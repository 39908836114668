import { Button } from '@mui/material'
import menu from '../../data/menu.json'

const Sidebar = () => {
    const location = window.location.pathname

    const menuItems = (parent = true) => {
        const filter = (item) => parent ? item.parent === "/" && location === "/" : (item.parent !== "/" && item.parent === location)
        return menu.widgetItems.filter(filter)
    }

    return (
        <div style={{
            position: 'fixed',
            backgroundColor: '#ececec',
            padding: '.25rem',
            width: '210px',
            height: 'calc(100vh - 80px)',
            top: 70,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            {menuItems(true).map((item, index) => (
                <Button key={index} style={{ width: '200px', marginTop: '8px' }} variant={(location === item.href ? "contained" : "outlined")} href={item.href}>{item.name}</Button>
            ))}
            {menuItems(false).map((item, index) => (
                <Button key={index} style={{ width: '200px', marginTop: '8px' }} variant={(location === item.href ? "contained" : "outlined")} href={item.href}>{item.name}</Button>
            ))}
        </div>
    )
}
export default Sidebar