import { useEffect, useRef, useState } from "react"
import UseGetNutritionalLabel from '../actions/getNutritionLabel'
import { Autocomplete, Avatar, Button, CircularProgress, FormControl, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { MdOutlineRemoveCircleOutline, MdTurnedIn } from "react-icons/md";
import UseAutoComplete from "../actions/autoComplete";
import { useDispatch } from "react-redux";
import UseGetUtility from "../actions/getUtility";
import ReactToPrint from 'react-to-print';


// const labelDiv = window.document.createElement('div')
// labelDiv.innerHTML = data.nutritionLabel

const exampleFormulary = [
    { id: 292, name: 'Chicken Breast', quantity: 145, unit: 'g' },
    { id: 1373, name: 'Jasmine Rice', quantity: 220, unit: 'g' },
    { id: 199, name: 'Broccoli', quantity: 245, unit: 'g' },
    { id: 1988, name: '5 Blend Cheese', quantity: 210, unit: 'g' },
    { id: 79, name: 'Banana', quantity: 85, unit: 'g' },
]

const NutritionLabel = () => {
    const dispatch = useDispatch()
    const componentRef = useRef();

    const [data, setData] = useState("")
    const [formulary, setFormulary] = useState([])
    const [ingredient, setIngredient] = useState({
        unit: {
            "_object": "Unit",
            "id": 44,
            "name": "Gram",
            "abbreviation": "g"
        }
    })
    const [showLabel, setShowLabel] = useState(false)
    const [query, setQuery] = useState("")
    const [results, setResults] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [utility, setUtility] = useState(null)

    const fetchUtilities = async () => {
        const { utility } = await dispatch(UseGetUtility("unit"))

        console.log(utility)
        setUtility({ Unit: utility.Unit })
    }

    useEffect(() => {
        console.log("data :: -- ", data)
    }, [data])

    useEffect(() => {
        fetchUtilities()
    }, [])


    const fetchAutoCompleteData = async () => {
        const data = await dispatch(UseAutoComplete(query))
        setResults(data)
    }


    // Get auto complete data
    useEffect(() => {

        if (query === "") {
            return setResults([])
        }

        if (query.length > 1) fetchAutoCompleteData()

    }, [query])


    const fetchNutritionLabel = async () => {
        // if (formulary.length < 2) return console.log("formulary must be over 2 ingredients")
        console.log(formulary)
        const response = await dispatch(UseGetNutritionalLabel(formulary))

        // console.log(response)

        setData(response.nutritionLabel)
    }

    const addToFormulary = () => {
        console.log({ selectedItem, ingredient })

        if (!selectedItem.id) return console.log("missing id")
        if (!ingredient.quantity) return console.log("missing quantity")
        if (!ingredient.unit) return console.log("missing unit")

        const newIngredient = {
            id: selectedItem.id,
            masterId: selectedItem.masterId,
            name: selectedItem.food_name,
            quantity: ingredient.quantity,
            unit: ingredient.unit.name,
            unitId: ingredient.unit.id,
            // unitId: ingredient.unit.abbreviation,
            type: selectedItem.common_type,
        }

        setFormulary([...formulary, newIngredient])
        setSelectedItem(null)
        setIngredient({
            unit: {
                "_object": "Unit",
                "id": 44,
                "name": "Gram",
                "abbreviation": "g"
            }
        })
        setQuery("")
    }


    const handleRemoveIngredient = (index) => {
        let newIngs = formulary.filter((ing, i) => i !== index)
        setFormulary(newIngs)

    }

    function PrintElem(elem) {
        var mywindow = window.open('', 'PRINT', 'height=900,width=500');

        mywindow.document.write('<html><head><title>' + document.title + '</title>');
        // mywindow.document.write("<link rel=\"stylesheet\" type=\"text/css\" media=\"print\"/>");
        mywindow.document.write('</head><body >');
        mywindow.document.write('<h1>' + document.title + '</h1>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        // mywindow.document.write(data);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        setTimeout(function () {
            mywindow.print();
            // mywindow.close();
        }, 1000);

        // mywindow.print();

        return true;
    }

    if (showLabel && !data) {
        return (
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress />
            </div>
        )
    }

    if (showLabel && data) return (
        <div style={{
            // padding: '1rem'
        }}>
            <div style={{
                padding: '1rem'
            }} id="nutrition-label" dangerouslySetInnerHTML={{ __html: data }} ref={componentRef} />
            <div style={{
                borderTop: '1px #767676 solid',
                padding: '1rem 0rem',
                margin: '1rem',
            }}>
                <ReactToPrint
                    trigger={() => <Button style={{ marginRight: '8px' }} variant="outlined">Print</Button>}
                    content={() => componentRef.current}
                />
                {/* <Button style={{ marginRight: '8px' }} onClick={() => { PrintElem("nutrition-label") }} variant="outlined">Print</Button> */}
                {/* <Button onClick={() => { console.log(window); window.print() }} variant="outlined">Export</Button> */}
            </div>
        </div >
    )

    if (!utility) {
        return (
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress />
            </div>
        )
    }

    console.log("data :: ", data)

    return (
        <div style={{ padding: '0rem 1rem' }}>
            <h1>Nutritional Label</h1>


            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                // flexDirection: 'column',
                // maxWidth: '200px'
            }}>
                <TextField style={{ flexBasis: "450px", marginRight: '16px', marginBottom: '2px' }} label="Ingredient" onChange={e => setQuery(e.target.value)} value={query} />
                <TextField style={{ flexBasis: "150px", marginRight: '16px', marginBottom: '2px' }} label="Quantity" onChange={e => setIngredient({ ...ingredient, quantity: parseFloat(e.target.value) })} value={ingredient?.quantity ? ingredient.quantity : ""} />

                <Autocomplete
                    value={ingredient?.unit || null}
                    // defaultValue={{ id: 44 }}
                    onChange={(e, v) => v && setIngredient({ ...ingredient, unit: v })}
                    getOptionLabel={(item) => item?.name}
                    loading={utility.Unit?.length > 0}
                    loadingText="Loading"
                    disablePortal
                    id="units"
                    options={utility.Unit}
                    sx={{ marginRight: '16px', flexBasis: '225px' }}
                    renderInput={(params) => <TextField size="small" {...params} label="Units" />}
                />

                <Button disabled={ingredient.unit && ingredient.quantity && selectedItem ? false : true} variant={ingredient.unit && ingredient.quantity && selectedItem ? "contained" : "outlined"} onClick={addToFormulary}> add to formulary</Button>
            </div>

            <List style={{
                position: 'absolute',
                backgroundColor: '#fff',
                zIndex: 20,
            }}>

                {results.common?.length > 0 && <p>Common</p>}
                {results.common && results.common.map((item, index) => (
                    <ListItem disablePadding key={index}>
                        <ListItemButton component="a" onClick={() => { setSelectedItem(item); setQuery(`${item.id} - ${item.food_name}`); setResults([]) }}>
                            <ListItemAvatar>
                                <Avatar>
                                    <img src={item.photo.highres} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.food_name} />
                        </ListItemButton>
                    </ListItem>
                ))}

                {results.branded?.length > 0 && <p>Branded</p>}
                {results.branded && results.branded.map((item, index) => (
                    <ListItem disablePadding key={index}>
                        <ListItemButton component="a" onClick={() => { setSelectedItem(item); setQuery(`${item.id} - ${item.food_name}`); setResults([]) }}>
                            <ListItemAvatar>
                                <Avatar>
                                    <img src={item.photo.highres} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.food_name} />
                        </ListItemButton>
                    </ListItem>
                ))}

            </List>

            {/* <Button variant="outlined" onClick={fetchNutritionLabel}> get</Button> */}

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 800 }}>Ingredient</TableCell>
                            <TableCell style={{ fontWeight: 800 }} width={'20%'}>Quantity</TableCell>
                            <TableCell style={{ fontWeight: 800 }} width={'20%'}>Unit</TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                {/* <Button disabled={formulary.length < 2} variant="outlined" onClick={() => { fetchNutritionLabel(); setShowLabel(true) }}> get</Button> */}
                                <Button disabled={formulary.length === 0 ? true : false} variant={formulary.length >= 1 ? "contained" : "outlined"} onClick={() => { fetchNutritionLabel(); setShowLabel(true) }}>Get Label</Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {formulary.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell width={'40%'}>{item.name}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>{item.unit}</TableCell>
                                <TableCell><MdOutlineRemoveCircleOutline onClick={() => handleRemoveIngredient(index)} /></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div >
    )

}

export default NutritionLabel
