import { setNotification } from '../store/NotificationContext'
import api from '../utility/api'

const UseGetUtility = (query) => async (dispatch) => {

    return new Promise(async resolve => {

        const response = await api.get(`/api/v2-b2b/utility?type=${query}`).catch(err => err.response)

        if (response.status === 400) {
            console.log("response status is 400")
            return dispatch(setNotification({
                msg: response.data.error
            }))
        }

        // console.log("response :: ", response)

        return resolve(response.data)

    })

}

export default UseGetUtility