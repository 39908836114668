import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NutritionLabel from "../widgets/nutritionLabel";
import ItemDetail from "../widgets/itemDetail";
import FoodCategories from "../widgets/foodCategories";
import SubproductCreate from "../widgets/subproductCreate";
import ProductCreate from "../widgets/productCreate";
import AutoComplete from "../widgets/autoComplete";
import SuccessConfirmation from "../widgets/elements/successConfirmation";
import CustomDiet from "../widgets/customDiet";
import FoodNameNormalization from "../widgets/foodNameNormalization";
import Authorization from "../components/authorization";
import ReferenceFoods from "../components/referenceFoods";
import CreateRecipe from "../components/createRecipe";
import RecipeInsights from "../components/recipeInsights";


const Widgets = () => {
    return (
        <Router>
            <Routes>

                {/* WIDGETS */}
                <Route path="/widget/item-detail" element={<ItemDetail />} />
                <Route path="/widget/food-categories" element={<FoodCategories />} />
                <Route path="/widget/recipe-create" element={<SubproductCreate />} />
                <Route path="/widget/product-create" element={<ProductCreate />} />
                <Route path="/widget/nutrition-label" element={<NutritionLabel />} />
                <Route path="/widget/autocomplete" element={<AutoComplete />} />
                <Route path="/widget/custom-diet" element={<CustomDiet />} />
                <Route path="/widget/food-name-normalization" element={<FoodNameNormalization />} />

                {/* ADD A CATCH ALL */}

            </Routes>
        </Router>
    )
}

export default Widgets