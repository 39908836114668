import { useState } from "react";
import { AppBar, Button, Container, Link, Stack, Toolbar } from "@mui/material"
import useWindowWidth from '../../utility/useWindowWidth';
import MobileMenu from "./MobileMenu";
import menu from '../../data/menu.json'

const Navbar = () => {
    const windowWidth = useWindowWidth();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <AppBar>
            <Container>
                <Toolbar style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Link href="/">
                        <img src="/images/logo.svg" style={{
                            fill: "#fff"
                        }} />
                    </Link>

                    {windowWidth >= 700 && <Stack direction='row'>
                        {menu.menuItems.map((item, index) => (
                            <Link href={item.href} key={index}>
                                <Button
                                    href={item.href}
                                    key={index}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {item.name}
                                </Button>
                            </Link>
                        ))}
                    </Stack>}

                    {windowWidth <= 700 && <MobileMenu setMobileMenuOpen={setMobileMenuOpen} mobileMenuOpen={mobileMenuOpen} />}

                </Toolbar>
            </Container>

        </AppBar>
    )

}

export default Navbar