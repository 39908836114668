import { baseUrl } from "../actions/settings";
import axios from "axios";

const api = axios.create({
    baseURL: baseUrl,
})

api.interceptors.request.use((config) => {
    config.headers['x-api-key'] = localStorage.getItem("apiKey");
    config.headers['x-client-id'] = localStorage.getItem("clientId");
    config.headers['authorization'] = localStorage.getItem("authorization");

    return config;
});

export default api;