console.log("settings.js - ENVRIONMENT: ", process.env.NODE_ENV)

export const baseUrl = "https://healiapp.com"
// exports.baseUrl = "http://localhost:3000"

export const config = {
    headers: {
        'x-api-key': localStorage.getItem("apiKey"),
        'x-client-id': localStorage.getItem("clientId"),
        'authorization': localStorage.getItem("authorization")
    }
}