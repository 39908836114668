import { Autocomplete, Avatar, Button, Chip, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from "@mui/material"
import UseGetUtility from "../actions/getUtility"
import { useCallback, useEffect, useState } from "react"
import { useDispatch } from 'react-redux'

import UseEngine from "../actions/engine"
import UseAutoComplete from "../actions/autoComplete"
import UseGetItemDetails from "../actions/getItemDetails"
import { MdOutlineDeleteForever } from "react-icons/md";


const CustomDiet = () => {
    const dispatch = useDispatch()

    const [utility, setUtility] = useState({})

    const [diet, setDiet] = useState({
        eatMore: [],
        eatLess: [],
        eatMoreTags: [],
        eatLessTags: [],
        avoidTags: [],

    })

    const [engineRes, setEngineRes] = useState({})
    const [results, setResults] = useState(null)
    const [query, setQuery] = useState("")
    const [item, setItem] = useState(null)

    const fetchGetItems = useCallback(async () => {
        const results = await dispatch(UseAutoComplete(query))

        // console.log(results)
        setResults(results)
    },[])

    useEffect(() => {
        if (query.length > 1) fetchGetItems()
    }, [fetchGetItems, query])

    const fetchUtilities = async () => {
        const { utility } = await dispatch(UseGetUtility("all"))

        // console.log(utility)
        setUtility(utility)
    }

    useEffect(() => {
        fetchUtilities()
    }, [])

    const handleSubmit = async () => {
        console.log("TEST TESTING")

        let modifiedDiet = {
            eatMore: [],
            eatLess: [],
            eatMoreTags: [],
            eatLessTags: [],
            avoidTags: []
        }

        for (let i = 0; i < diet.eatMore.length; i++) modifiedDiet.eatMore.push(diet.eatMore[i].id)
        for (let i = 0; i < diet.eatLess.length; i++) modifiedDiet.eatLess.push(diet.eatLess[i].id)
        for (let i = 0; i < diet.eatMoreTags.length; i++) modifiedDiet.eatMoreTags.push(diet.eatMoreTags[i].id)
        for (let i = 0; i < diet.avoidTags.length; i++) modifiedDiet.avoidTags.push(diet.avoidTags[i].id)

        let modifiedIngredients = [
            //     {
            //         "id": 291,
            //         "unitId": 44,
            //         "quantity": 0.016
            //     },
        ]

        for (let i = 0; i < item.ingredients.length; i++) modifiedIngredients.push({ id: item.ingredients[i].id, unitId: item.ingredients[i].through.unitId || 44, quantity: item.ingredients[i].through.quantity })

        let response = await dispatch(UseEngine({ diet: modifiedDiet, ingredients: modifiedIngredients, servings: 2 }))

        setEngineRes({ ...response })


        // console.log(response)
        // console.log({ diet: diet, ingredients, servings: 2 })

    }

    const handleGetItem = async (item) => {
        // console.log(item)
        if (item && item.id) {

            let response = await dispatch(UseGetItemDetails({ productId: item.id }))

            // console.log(response)
            setItem(response.item)
        }

    }

    if (!utility) return <p>Loading</p>

    console.log(item)
    return (
        <div style={{ padding: '1rem' }}>
            <h1>Create Custom Diet</h1>

            <div>
                {item ?
                    <div style={{
                        display: "flex",
                        alignItems: 'center'
                    }}>
                        <MdOutlineDeleteForever style={{ marginRight: '16px' }} size={24} onClick={() => setItem(null)} />
                        <p style={{ margin: "0px" }}>{item.id} - {(item.name || item.food_name)}</p>
                    </div>
                    :
                    <TextField fullWidth label="Search Product" onChange={e => setQuery(e.target.value)} />
                }
                <List style={{
                    position: 'absolute',
                    backgroundColor: '#fff',
                    zIndex: 20,
                }}>

                    {results?.branded?.length > 0 && <p>Branded</p>}
                    {results?.branded && results?.branded.map((item, index) => (
                        <ListItem disablePadding key={index}>
                            <ListItemButton component="a" onClick={() => { setItem(item); setResults(null); handleGetItem(item) }}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src={item.photo.highres} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.food_name} />
                            </ListItemButton>
                        </ListItem>
                    ))}

                </List>

                {/* LifeStyle Select: diets  */}
                <div style={{
                    flexBasis: "400px",
                    marginTop: '8px',
                    // marginRight: '8px'
                }}>
                    {/* <p>Lifestyle Select</p> */}
                    <Autocomplete
                        // onChange={(e, v) => v && setDetails({ ...details, diet: [...details.diet, v] })}
                        disabled
                        getOptionLabel={(item) => item?.name}
                        fullWidth
                        loading={utility.Diet?.length > 0}
                        loadingText="Loading"
                        disablePortal
                        id="diets"
                        options={utility.Diet}
                        // sx={{ marginRight: '16px' }}
                        renderInput={(params) => <TextField size="small" {...params} label="Lifestyle Select" />}
                    />
                </div>


                {/* Eat More: nutrients and tags */}
                <div>
                    <h3 style={{ marginBottom: '0px' }}>Eat More</h3>
                    <div style={{
                        display: 'flex',
                        // justifyContent: 'space-evenly'
                    }}>
                        <div style={{
                            // flexBasis: "400px",
                            flexBasis: "50%",
                            marginTop: '8px',
                            marginRight: '8px'
                        }}>
                            {/* <p>Nutrients</p> */}
                            <Autocomplete
                                onChange={(e, v) => v && setDiet({ ...diet, eatMore: [...diet.eatMore, v] })}
                                getOptionLabel={(item) => item?.name}
                                fullWidth
                                loading={utility.Nutrient?.length > 0}
                                loadingText="Loading"
                                disablePortal
                                id="eat-more-nutrients"
                                options={utility.Nutrient}
                                // sx={{ marginRight: '16px' }}
                                renderInput={(params) => <TextField size="small" {...params} label="Nutrients" />}
                            />

                            {diet.eatMore.length > 0 &&

                                <div>
                                    {diet.eatMore.map((item, index) => (
                                        <Chip key={index} label={item.name} style={{ marginRight: '4px', marginTop: '2px', marginBottom: '2px' }} />
                                    ))}
                                </div>

                            }

                        </div>

                        <div style={{
                            flexBasis: "50%",
                            marginTop: '8px',
                            // marginRight: '8px'
                        }}>
                            {/* <p>Tags</p> */}
                            <Autocomplete
                                onChange={(e, v) => v && setDiet({ ...diet, eatMoreTags: [...diet.eatMoreTags, v] })}
                                getOptionLabel={(item) => item?.name}
                                fullWidth
                                loading={utility.Tag?.length > 0}
                                loadingText="Loading"
                                disablePortal
                                id="tag-eat-more"
                                options={utility.Tag}
                                // sx={{ marginRight: '16px' }}
                                renderInput={(params) => <TextField size="small" {...params} label="Tags" />}
                            />

                            {diet.eatMoreTags.length > 0 &&
                                <div>
                                    {diet.eatMoreTags.map((item, index) => (
                                        <Chip key={index} label={item.name} style={{ marginRight: '4px', marginTop: '2px', marginBottom: '2px' }} />
                                    ))}
                                </div>
                            }

                        </div>
                    </div>
                </div>


                {/* Eat Less: nutrients and tags */}
                <div>
                    <h3 style={{ marginBottom: '0px' }}>Eat Less</h3>
                    <div style={{
                        display: 'flex',
                        // flexWrap: 'wrap'
                        // justifyContent: 'space-evenly'
                    }}>
                        <div style={{
                            // flexBasis: "400px",
                            flexBasis: "50%",
                            marginTop: '8px',
                            marginRight: '8px'
                        }}>
                            {/* <p>Nutrients</p> */}
                            <Autocomplete
                                onChange={(e, v) => v && setDiet({ ...diet, eatLess: [...diet.eatLess, v] })}
                                getOptionLabel={(item) => item?.name}
                                fullWidth
                                loading={utility.Nutrient?.length > 0}
                                loadingText="Loading"
                                disablePortal
                                id="eat-less-nutrients"
                                options={utility.Nutrient}
                                // sx={{ marginRight: '16px' }}
                                renderInput={(params) => <TextField size="small" {...params} label="Nutrients" />}
                            />

                            {diet.eatLess.length > 0 &&
                                <div>
                                    {diet.eatLess.map((item, index) => (
                                        <Chip key={index} label={item.name} style={{ marginRight: '4px', marginTop: '2px', marginBottom: '2px' }} />
                                    ))}
                                </div>
                            }

                        </div>

                        <div style={{
                            flexBasis: "50%",
                            marginTop: '8px',
                            // marginRight: '8px'
                        }}>
                            {/* <p>Tags</p> */}
                            <Autocomplete
                                onChange={(e, v) => v && setDiet({ ...diet, eatLessTags: [...diet.eatLessTags, v] })}
                                getOptionLabel={(item) => item?.name}
                                fullWidth
                                loading={utility.Tag?.length > 0}
                                loadingText="Loading"
                                disablePortal
                                id="tag-eat-less"
                                options={utility.Tag}
                                // sx={{ marginRight: '16px' }}
                                renderInput={(params) => <TextField size="small" {...params} label="Tags" />}
                            />

                            {diet.eatLessTags.length > 0 &&
                                <div>
                                    {diet.eatLessTags.map((item, index) => (
                                        <Chip key={index} label={item.name} style={{ marginRight: '4px', marginTop: '2px', marginBottom: '2px' }} />
                                    ))}
                                </div>
                            }

                        </div>
                    </div>
                </div>



                {/* Avoid: tags */}
                <div>
                    <h3 style={{ marginBottom: '0px' }}>Avoid</h3>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <div style={{
                            // flexBasis: "400px",
                            flexBasis: "50%",
                            marginTop: '8px',
                            // marginRight: '8px'
                        }}>
                            {/* <p>Nutrients</p> */}
                            <Autocomplete
                                onChange={(e, v) => v && setDiet({ ...diet, avoidTags: [...diet.avoidTags, v] })}
                                getOptionLabel={(item) => item?.name}
                                fullWidth
                                loading={utility.Tag?.length > 0}
                                loadingText="Loading"
                                disablePortal
                                id="avoid-tags"
                                options={utility.Tag}
                                // sx={{ marginRight: '16px' }}
                                renderInput={(params) => <TextField size="small" {...params} label="Tag" />}
                            />

                            {diet.avoidTags.length > 0 &&
                                <div>
                                    {diet.avoidTags.map((item, index) => (
                                        <Chip key={index} label={item.name} style={{ marginRight: '4px', marginTop: '2px', marginBottom: '2px' }} />
                                    ))}
                                </div>
                            }

                        </div>
                    </div>
                </div>


                <Button onClick={handleSubmit}>Submit</Button>
            </div>

            <h3>Match Score for { }</h3>

            <div>

                {engineRes.prosCons?.cons.length > 0 &&
                    <div>
                        <p>Cons</p>
                        <div>
                            {engineRes.prosCons?.cons.map((item, index) => (
                                <Chip key={index} label={item.name} style={{ backgroundColor: 'red', marginRight: '4px', marginBottom: '2px' }} />
                            ))}
                        </div>
                    </div>
                }

                {engineRes.prosCons?.pros.length > 0 &&
                    <div>
                        <p>Pros</p>
                        <div>
                            {engineRes.prosCons?.pros.map((item, index) => (
                                <Chip  key={index} label={item.name} style={{ backgroundColor: 'green', marginRight: '4px', marginBottom: '2px' }} />
                            ))}
                        </div>
                    </div>
                }

            </div>



            <div>

                {Object.keys(engineRes).length > 0 &&

                    engineRes.nutrients.map((item, index) => (
                        <div key={index} style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <p style={{ flexBasis: '120px', marginRight: '8px' }}>{item.name}</p>
                            <LinearProgress style={{ flexBasis: '50%', marginRight: '8px' }} variant="determinate" value={item.dv} />
                            <p style={{ flexBasis: '10%' }}>{item.quantityText}</p>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default CustomDiet