import { useLocation } from 'react-router-dom';

const SuccessConfirmation = () => {
    const location = useLocation();
    const { data } = location.state

    return (
        <div style={{
            padding: '1rem'
        }}>
            <h1>{data.newCreatedProduct?.id || data.newlyCreatedSyn?.masterId || data.newlyCreatedSyn?.id} - {data.name}</h1>
            <p>{data.description}</p>
            <p>Serving Size: {data.servingSizeQuantity}g</p>

        </div>
    )
}

export default SuccessConfirmation