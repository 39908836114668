

const ProductDetails = ({ selectedItem }) => {
    return (
        <div>
            <p>{selectedItem.id} - {selectedItem.food_name}</p>
            <img src={selectedItem.photo.highres} />
        </div>
    )
}

export default ProductDetails