import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    msg: null,
    type: null,
    display: "none"
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotification: (state, action) => {
            console.log(" --- setnotification :: ", action.payload)
            state.msg = action.payload.msg
            state.type = action.payload.type
            state.display = "block"

        },
        clearNotification: (state) => {
            state.msg = null
            state.type = null
            state.display = "none"
        }
    },
})

// Action creators are generated for each case reducer function
export const { setNotification, clearNotification } = notificationSlice.actions

export default notificationSlice.reducer