import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearNotification } from "../../store/NotificationContext"


const Notification = () => {
    const dispatch = useDispatch()
    const { msg, display } = useSelector(state => state.notification)

    const [styling] = useState({
        backgroundColor: 'rgb(255 50 50)'
    })

    useEffect(() => {
        if (msg) {
            setTimeout(() => {
                dispatch(clearNotification())
            }, 2500);
        }
    }, [msg])

    return (
        <div style={{
            zIndex: 2000,
            position: 'absolute',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <div style={{
                display: display,
                padding: '2rem 4rem',
                margin: '1rem',
                backgroundColor: styling.backgroundColor,
                top: 0,
                boxShadow: "0 0 20px 0px rgba(0, 0, 0, 0.5)",
                borderRadius: "8px",
                color: 'white',

            }}>
                <p style={{
                    fontSize: '1.5rem',
                }}>{msg}</p>
            </div>
        </div>
    )
}

export default Notification