const CreateRecipe = () => {
    return (
        <div style={{
            padding: '0rem 1rem'
        }}>
            <h1>Create Recipe</h1>
            <p>Use Create Product to add an ingredient that’s based on a packaged good that already has its own set of nutrition fact. In this flow you would be including the name of product and the nutrition fact panel, including each nutrition fact and the ingredient declaration. In this endpoint we won’t be making assumptions on nutrition facts. We’ll use only what you provide.
            </p>
            <p>Use Create Recipe to add an ingredient that’s based on a recipe. In this flow you would be including each ingredient and the amount of each ingredient. In this endpoint we will do the nutritional analysis independently.
            </p>
            <p>If you want to create a recipe strictly with your own ingredients and your own nutrition facts for each ingredient then you’ll need to use Create Product for each of the unique recipe. You will then use Create Recipe to reference each of your new ingredients.
            </p>
            <p>Note: Any products or recipes you create will only be visible to you. (edited)

            </p>
        </div>
    )
}

export default CreateRecipe