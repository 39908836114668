const ReferenceFoods = () => {
    return (
        <div style={{
            padding: '0rem 1rem'
        }}>
            <h1>Reference Foods</h1>
            <p>Use API Endpoints under references foods to match your own ingredient database with Heali’s ingredient database. Once you’ve completed matching ingredients between databases you will be able to create a recipe.</p>
            <p>
                If you would like to add your own unique ingredients you can go to the “Create Recipe” section above.

            </p>

        </div>
    )
}

export default ReferenceFoods