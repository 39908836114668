import { useCallback, useEffect, useState } from "react"
import { Autocomplete, Avatar, Button, CircularProgress, FormControl, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { MdOutlineRemoveCircleOutline } from "react-icons/md";
import NewSubproductConfirmation from './elements/newSubproductConfirmation'
import UseAutoComplete from '../actions/autoComplete';
import createProduct from "../actions/createProduct"
import { useNavigate } from 'react-router-dom'
import UseGetUtility from "../actions/getUtility";
import { useDispatch } from "react-redux";

// ###############
// Create a Recipe

const SubproductCreate = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [product, setProduct] = useState({
        servingSizeUnit: {
            "_object": "Unit",
            "id": 44,
            "name": "Gram",
            "abbreviation": "g"
        }
    })
    const [ingredients, setIngredients] = useState([])
    const [selectedIngredient, setSelectedIngredient] = useState(null)
    const [selectedIngredientQuantity, setSelectedIngredientQuantity] = useState(0)
    const [selectedIngredientUnit, setSelectedIngredientUnit] = useState({
        "_object": "Unit",
        "id": 44,
        "name": "Gram",
        "abbreviation": "g"
    })
    const [query, setQuery] = useState("")
    const [results, setResults] = useState([])
    const [showNewProductConfirmation, setShowNewProductConfirmation] = useState(false)
    const [temp, setTemp] = useState({})
    const [utility, setUtility] = useState(null)


    const fetchUtilities = useCallback(async () => {
        const { utility } = await dispatch(UseGetUtility("unit"))

        console.log(utility)
        if (utility) setUtility({ Unit: utility.Unit })
    }, [dispatch]);

    useEffect(() => {
        fetchUtilities()
    }, [fetchUtilities])



    const fetchAutoCompleteData = useCallback(async () => {
        const data = await dispatch(UseAutoComplete(query))
        setResults(data)
    }, [dispatch, query])

    // Get auto complete data
    useEffect(() => {

        if (query === "") {
            return setResults([])
        }

        if (query.length > 1) fetchAutoCompleteData()

    }, [query, fetchAutoCompleteData])

    // // Filter data
    // useEffect(() => {
    //     const filtered = data.filter(d => d.food_name.toLowerCase().includes(query.toLowerCase()))
    //     setResults(filtered)

    //     if (query === "") {
    //         setResults([])
    //     }

    // }, [query])

    const handleAddToIngredients = () => {
        let newIngredient = {
            id: selectedIngredient.id,
            masterId: selectedIngredient.masterId,
            name: selectedIngredient.food_name,
            quantity: selectedIngredientQuantity,
            unit: selectedIngredientUnit
        }

        setIngredients([...ingredients, newIngredient])

        setQuery("")
        setSelectedIngredient(null)
        setSelectedIngredientQuantity(0)
        setSelectedIngredientUnit({
            "_object": "Unit",
            "id": 44,
            "name": "Gram",
            "abbreviation": "g"
        })
    }

    const handleRemoveIngredient = (index) => {
        let newIngs = ingredients.filter((ing, i) => i !== index)
        setIngredients(newIngs)

    }

    const handleReviewProduct = () => {
        let body = {
            ...product,
            formulary: ingredients
        }

        console.log(body)
        setTemp({ ...temp, newProduct: body })
        setShowNewProductConfirmation(true)
    }

    const handleSubmitProduct = async () => {
        let body = {
            ...product,
            ingredients
        }

        console.log(body)
        const response = await dispatch(createProduct(body, "subproduct"))

        navigate('/success-confirmation', { state: { msg: "You have successfully created a product", data: response.data, nextPath: '/subproduct-create' } })

    }


    const validation = () => {
        let submitDisabled = false

        if (ingredients.length < 2) submitDisabled = true
        if (!product.name || !product.description) submitDisabled = true
        if (!product.productTypes || !product.servingSizeQuantity) submitDisabled = true


        return { submitDisabled }
    }


    if (showNewProductConfirmation) {
        return <NewSubproductConfirmation newProduct={temp.newProduct} setShowNewProductConfirmation={setShowNewProductConfirmation} handleSubmitProduct={handleSubmitProduct} />
    }

    if (!utility) {
        return (
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div style={{
            // border: '1px red solid',
            padding: '0rem 1rem',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <div>
                <h1 style={{ marginBottom: '0px' }}>Create a Recipe</h1>
                <span>Using Heali Intel</span>

                <TextField sx={{ marginTop: '16px' }} fullWidth label="Food Name" onChange={e => setProduct({ ...product, name: e.target.value })} />
                <TextField sx={{ marginTop: '16px' }} fullWidth label="Description" onChange={e => setProduct({ ...product, description: e.target.value })} />

                <div style={{ padding: '16px 0px', borderBottom: '2px #767676 solid', display: 'flex', flexWrap: 'wrap' }}>

                    <FormControl style={{
                        flexBasis: '300px',
                        marginRight: '16px'
                    }}>
                        <InputLabel id="food-type-label-id">Food Type</InputLabel>
                        <Select labelId="food-type-label-id" label={"Food Type"} style={{ marginRight: '16px' }} fullWidth onChange={e => setProduct({ ...product, productTypes: e.target.value })}>
                            <MenuItem value="indFood">Individual Food</MenuItem>
                            <MenuItem value={"meal"}>Meal</MenuItem>
                            <MenuItem value={"mainDish"}>Main dish</MenuItem>
                            <MenuItem value={"supp"}>Supplement</MenuItem>
                            <MenuItem value={"other"}>Other</MenuItem>
                        </Select>
                    </FormControl>

                    <div style={{
                        flexBasis: '300px',
                        // marginRight: '16px'
                    }}>
                        {/* <p>Quantity</p> */}
                        {/* <p>Quantity</p> */}
                        <TextField label="Quantity" onChange={e => setProduct({ ...product, servingSizeQuantity: parseInt(e.target.value) })} />
                    </div>

                    <div style={{
                        flexBasis: '300px',
                        marginRight: '16px'
                    }}>
                        <Autocomplete
                            value={product?.servingSizeUnit || null}
                            onChange={(e, v) => v && setProduct({ ...product, servingSizeUnit: v })}
                            getOptionLabel={(item) => item?.name}
                            loading={utility?.Unit?.length > 0}
                            loadingText="Loading"
                            disablePortal
                            id="units"
                            options={utility.Unit}
                            renderInput={(params) => <TextField size="small" {...params} label="Units" />}
                        />
                    </div>

                </div>

                <div>
                    <div style={{
                        margin: '16px 0px',
                        display: 'flex',
                    }}>
                        <TextField sx={{ marginRight: '16px' }} label={"Ingredient"} fullWidth onChange={e => { setQuery(e.target.value) }} value={query} />
                        <TextField label='Quantity' sx={{ flexBasis: '20%', marginRight: '16px' }} onChange={e => setSelectedIngredientQuantity(parseInt(e.target.value))} value={selectedIngredientQuantity ? selectedIngredientQuantity : ""} />
                        <div style={{
                            flexBasis: '300px',
                            marginRight: '16px'
                        }}>
                            <Autocomplete
                                value={selectedIngredientUnit || null}
                                onChange={(e, v) => v && setSelectedIngredientUnit(v)}
                                getOptionLabel={(item) => item?.name}
                                loading={utility?.Unit?.length > 0}
                                loadingText="Loading"
                                disablePortal
                                id="units"
                                options={utility.Unit}
                                renderInput={(params) => <TextField size="small" {...params} label="Units" />}
                            />
                        </div>

                        <Button disabled={selectedIngredientUnit && selectedIngredientQuantity && selectedIngredient ? false : true} variant={selectedIngredientUnit && selectedIngredientQuantity && selectedIngredient ? 'contained' : 'outlined'} onClick={handleAddToIngredients}>Add</Button>
                    </div>

                    <List style={{
                        position: 'absolute',
                        backgroundColor: '#fff',
                        zIndex: 20,
                    }}>

                        {results.common?.length > 0 && <p>Common</p>}
                        {results.common && results.common.map((item, index) => (
                            <ListItem disablePadding key={index}>
                                <ListItemButton component="a" onClick={() => { setSelectedIngredient(item); setQuery(`${item.id} - ${item.food_name}`); setResults([]) }}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <img src={item.photo.highres} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={item.food_name} />
                                </ListItemButton>
                            </ListItem>
                        ))}

                        {results.branded?.length > 0 && <p>Branded</p>}
                        {results.branded && results.branded.map((item, index) => (
                            <ListItem disablePadding key={index}>
                                <ListItemButton component="a" onClick={() => { setSelectedIngredient(item); setQuery(`${item.id} - ${item.food_name}`); setResults([]) }}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <img src={item.photo.highres} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={item.food_name} />
                                </ListItemButton>
                            </ListItem>
                        ))}

                    </List>


                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 800 }}>Ingredient</TableCell>
                                    <TableCell style={{ fontWeight: 800 }} width={'20%'}>Quantity</TableCell>
                                    <TableCell style={{ fontWeight: 800 }} width={'20%'}>Unit</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {ingredients.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.id} - {item.name}</TableCell>
                                        <TableCell>{item.quantity}</TableCell>
                                        <TableCell>{item.unit.name}</TableCell>
                                        <TableCell><MdOutlineRemoveCircleOutline onClick={() => handleRemoveIngredient(index)} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>

            <div>
                <Button disabled={validation().submitDisabled} onClick={handleReviewProduct} variant={validation().submitDisabled ? 'outlined' : "contained"}>Review</Button>
                {/* <Button disabled={validation().submitDisabled} onClick={handleReviewProduct} variant={validation().submitDisabled ? 'outlined' : "contained"}>TEST Review</Button> */}
            </div>

        </div>
    )
}

export default SubproductCreate