import { setNotification } from '../store/NotificationContext'
import api from '../utility/api'

const createProduct = (product, type) => async (dispatch) => {
    return new Promise(async resolve => {

        const response = await api.post(`/api/v2-b2b/${type}/create`, product).catch(err => err.response)

        if (response.status === 400) {
            console.log("response status is 400")
            return dispatch(setNotification({
                msg: response.data.error
            }))
        }


        return resolve(response)

    })

}

export default createProduct