const RecipeInsights = () => {
    return (
        <div style={{
            padding: '0rem 1rem'
        }}>
            <h1>Recipe Insights</h1>
            <p>Use Food Categories to get all types of nutritional insights on your recipe, product or ingredient. Enter in the recipe you already created and select the relevant nutrition insights you want explanations for.
            </p>
            <p>We offer nutritional insights for Diets, Nutrients, Regulated Claims, Ingredient Categories and Processing Categories. We also support in streamlining a FDA compliant Nutrition Fact Panel including Ingredient Declaration and Allergen Disclosure. These can be exported as PNGs.
            </p>
            <p>If you are only looking for regulatory requirements of a Nutrition Fact Panel then we recommend using the Nutrition Label endpoint for simpler experience.
            </p>
        </div>
    )
}

export default RecipeInsights