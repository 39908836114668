import { setNotification } from '../store/NotificationContext'
import api from '../utility/api'

const UseGetNutritionalLabel = (formulary) => (dispatch) => {

    let body = {
        "ingredients": [
            ...formulary
            // {
            //     id: 16180,
            //     masterId: 4921,
            //     name: 'Panko Bread Crumb',
            //     quantity: 40,
            //     unit: 'Gram',
            //     unitId: 44
            // }
        ],
        // "servingSizeQuantity": 315,
        // "servings": { quantity: 1, unit: "thing" },
        "servings": 1,
        // servingSizes: [{ quantity: 1, unit: "thing" }],
        "productTypes": ["indFood"]
    }


    return new Promise(async resolve => {

        const response = await api.post(`/api/v2-b2b/v2/nutrition-label`, body).catch(err => err.response)

        if (response.status === 400) {
            console.log("response status is 400")
            return dispatch(setNotification({
                msg: response.data.error
            }))
        }

        return resolve(response.data)
    })

}

export default UseGetNutritionalLabel

