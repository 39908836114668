import { Button, Drawer, IconButton } from '@material-ui/core';
import menu from '../../data/menu.json';
import useWindowWidth from '../../utility/useWindowWidth';
import { Menu as MenuIcon } from '@material-ui/icons';
import { Link } from '@mui/material';
import { unique } from 'radash';

const listItems = unique(menu.widgetItems, i => i.href)

const MobileMenu = ({ mobileMenuOpen, setMobileMenuOpen }) => {
    const windowWidth = useWindowWidth();

    const location = window.location.pathname;
    const isMobile = windowWidth <= 700;

    const handleMenuToggle = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    return (
        <div>
            {isMobile && (
                <div>
                    <IconButton onClick={handleMenuToggle}>
                        <MenuIcon />
                    </IconButton>
                    <Drawer anchor="left" open={mobileMenuOpen} onClose={handleMenuToggle}>
                        {listItems.map((item, index) => (
                            <Link key={index} href={item.href}>
                                <Button style={{ width: '200px', margin: '4px 6px' }} variant={(location === item.href ? "contained" : "outlined")} href={item.href}>{item.name}</Button>
                            </Link>
                        ))}
                    </Drawer>
                </div>
            )}
        </div>
    );
};

export default MobileMenu;
