import { baseUrl } from './settings'
import { setNotification } from '../store/NotificationContext'
import api from '../utility/api'

const UseAutoComplete = (query) => async (dispatch) => {
    return new Promise(async resolve => {

        console.log(baseUrl)
        const response = await api.get(`/api/v2-b2b/v2/search/instant?query=${query}`).catch(err => err.response)

        if (response?.status === 400) {
            console.log("response status is 400")
            return dispatch(setNotification({
                msg: response.data.error
            }))
        }

        return resolve(response?.data?.nx)
    })

}

export default UseAutoComplete
