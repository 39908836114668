import { setNotification } from '../store/NotificationContext'
import api from '../utility/api'

const UseGetIngredientIds = (body) => async (dispatch) => {

    return new Promise(async resolve => {

        const response = await api.post(`/api/v2-b2b/v2/food-name-match`, body).catch(err => err.response)

        if (response.status === 400) {
            console.log("response status is 400")
            return dispatch(setNotification({
                msg: response.data.error
            }))
        }

        // console.log("response get ingredient ids :: ", response)


        return resolve(response.data)

    })


}

export default UseGetIngredientIds