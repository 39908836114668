import { Button, Card, Chip, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useState } from "react";
import { camelCaseToTitleCase } from "../utilities";

const FoodCategoriesDisplay = ({ data, request }) => {
    const [selectedOption, setSelectedOption] = useState("");

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleDownload = () => {
        // Handle the download based on the selected option
        if (selectedOption === "csv") {
            downloadCsv();
        } else if (selectedOption === "json") {
            downloadJson();
        }
    };

    console.log("Food Categories Display", data)

    const headers = [
        "Ingredients",
        "Diet Name",
        "Avoids",
        "Eat Less",
        "Eat More",
        "Eat Less Tags",
        "Eat More Tags",
        "Match Score",
        "Pros",
        "Cons",

    ];

    const downloadCsv = () => {


        const csvRows = [];
        // Add headers to CSV rows
        csvRows.push(headers.join(","));
        // // Add data rows to CSV rows

        data.response.dietDetailsArray.forEach((item) => {
            csvRows.push([
                request.ingredients.map((item) => item.name).join("-"),
                item.name,
                item.avoidTags.map((item) => item.name).join("-"),
                item.eatLessTags.map((item) => item.name).join("-"),
                item.eatMoreTags.map((item) => item.name).join("-"),
                item.eatLess.map((item) => item.name).join("-"),
                item.eatMore.map((item) => item.name).join("-"),
                item.engine.matchScore,
                item.engine.prosCons.pros.map((item) => item.name).join("-"),
                item.engine.prosCons.cons.map((item) => item.name).join("-"),

            ].join(","));
        });
        // Join all CSV rows with newline character
        const csvString = csvRows.join("\n");
        // Create blob from CSV string
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
        // Create URL for blob
        const url = URL.createObjectURL(blob);
        // Create link element
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "food-data.csv");
        // Append link to body and click it to trigger download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadJson = () => {
        const jsonData = {
            dietDetailsArray: data.response.dietDetailsArray.map(item => ({
                ingredients: request.ingredients.map(ingredient => ingredient.name),
                name: item.name,
                avoidTags: item.avoidTags.map(tag => tag.name),
                eatLessTags: item.eatLessTags.map(tag => tag.name),
                eatMoreTags: item.eatMoreTags.map(tag => tag.name),
                eatLess: item.eatLess.map(food => food.name),
                eatMore: item.eatMore.map(food => food.name),
                engine: {
                    matchScore: item.engine.matchScore,
                    prosCons: {
                        pros: item.engine.prosCons.pros.map(pro => pro.name),
                        cons: item.engine.prosCons.cons.map(con => con.name)
                    }
                }
            }))
        };

        const jsonStr = JSON.stringify(jsonData, null, 2);
        const blob = new Blob([jsonStr], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "data.json");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <div style={{
            padding: '1rem'
        }}>
            <h1>Food Categories Display</h1>

            <div style={{
                padding: '8px 0px',
                // borderBottom: '2px #767676 solid'
            }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 800 }}>Requested Ingredients</TableCell>
                                <TableCell style={{ fontWeight: 800 }} width={'20%'}>Quantity</TableCell>
                                <TableCell style={{ fontWeight: 800 }} width={'20%'}>Unit</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {request.ingredients.map((item, index) => (
                                <TableRow>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                    <TableCell>{item.unit.name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            {data.includeOverallMatchScore && data.response.engine.matchScore && <div style={{ margin: '16px 0px' }}>Overall Match Score: <Chip label={data.response.engine.matchScore.toUpperCase()} /> </div>}
            {/* {data.response.engine.matchScore && <div style={{ margin: '16px 0px' }}>Overall Match Score: <Chip label={data.response.engine.matchScore.toUpperCase()} /> </div>} */}

            <div>
                {request.details.nutrients.length > 0 && <h3 style={{ marginBottom: '0px' }}>Nutrients</h3>}
                {request.details.nutrients.length > 0 && <h3 style={{ marginBottom: '0px' }}>Requested Nutrients</h3>}

                {request.details.nutrients.map((item, index) => (
                    <>
                        <Chip label={`${item.name}`} sx={{ margin: '2px 4px' }} />
                    </>
                ))}
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>


                    {data.response.nutrientDetails.map((item, index) => (
                        <Card style={{
                            flexBasis: '300px',
                            padding: '1rem',
                            margin: '2px'
                        }} variant="outlined">
                            <p style={{ fontWeight: 700 }}>{item.name}</p>
                            <p style={{ margin: '0px' }}>Quantity: {item.quantity?.toFixed(2)} {item.unit?.abbreviation}</p>
                            <p style={{ margin: '0px' }}>High: {item.high} / Low: {item.low}</p>
                            <p style={{ margin: '0px' }}>RACC: {item.racc}</p>
                            <p style={{ margin: '0px' }}>DV: {(item.quantity / item.racc * 100).toFixed(2)}%</p>
                        </Card>
                    ))}
                </div>
            </div>

            <div>
                {request.details.diet.length > 0 && <h3 style={{ marginBottom: '0px' }}>Diets</h3>}
                {request.details.diet.length > 0 && <h3 style={{ marginBottom: '0px' }}>Requested Diets</h3>}

                {request.details.diet.map((item, index) => (
                    <>
                        <Chip label={`${item.name}`} sx={{
                            margin: '2px 4px'
                        }} />
                    </>
                ))}
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>

                    {data.response.dietDetailsArray.map((item, index) => (
                        <Card style={{
                            flexBasis: '300px',
                            padding: '1rem',
                            margin: '2px'
                        }} variant="outlined">
                            <p style={{ fontWeight: 700 }}>{item.name} <Chip style={{ marginLeft: '8px' }} label={item.matchScore.toUpperCase()} /> </p>
                            {/* <p>Match Score: {item.matchScore}</p> */}
                            <p>{item.description}</p>

                            <p>Avoid</p>
                            {item.avoidTags.map((item, index) => (
                                <>
                                    <Chip label={`${item.name}`} sx={{
                                        margin: '2px 4px'
                                    }} />
                                </>
                            ))}

                            {item.eatMore.length > 0 || item.eatMoreTags.length > 0 && <p>Eat More</p>}

                            {item.eatMore.length > 0 ? item.eatMore.map((item, index) => (
                                <>
                                    <Chip label={`${item.name}`} sx={{
                                        margin: '2px 4px'
                                    }} />
                                </>
                            )) : null}
                            {item.eatMoreTags.length > 0 ? item.eatMoreTags.map((item, index) => (
                                <>
                                    <Chip label={`${item.name}`} sx={{
                                        margin: '2px 4px'
                                    }} />
                                </>
                            )) : null}

                            {item.eatLess.length > 0 || item.eatLessTags.length > 0 && <p>Eat Less</p>}
                            {item.eatLess.length > 0 ? item.eatLess.map((item, index) => (
                                <>
                                    <Chip label={`${item.name}`} sx={{
                                        margin: '2px 4px'
                                    }} />
                                </>
                            )) : null}

                            {item.eatLessTags.length > 0 ? item.eatLessTags.map((item, index) => (
                                <>
                                    <Chip label={`${item.name}`} sx={{
                                        margin: '2px 4px'
                                    }} />
                                </>
                            )) : null}

                            {item.prosCons.pros.length > 0 && <p>Pros</p>}
                            {item.prosCons.pros.length > 0 ? item.prosCons.pros.map((item, index) => (
                                <>
                                    <Chip label={`${item.quantityText} ${item.name}`} sx={{
                                        margin: '2px 4px'
                                    }} />
                                </>
                            )) : null}

                            {item.prosCons.cons.length > 0 && <p>Cons</p>}
                            {item.prosCons.cons.length > 0 ? item.prosCons.cons.map((item, index) => (
                                <>
                                    <Chip label={`${item.quantityText} ${item.name}`} sx={{
                                        margin: '2px 4px'
                                    }} />
                                </>
                            )) : null}

                            {/* {item.nutrients.map((item, index) => {
                                let eatMore = item.data[0].eatMore.filter(el => el.name === item.name)
                                console.log({ eatMore })
                            })} */}



                        </Card>
                    ))}
                </div>
            </div>

            <div>
                {request.details.claims.length > 0 && <h3 style={{ marginBottom: '0px' }}>Claims</h3>}
                {request.details.claims.length > 0 && <h3 style={{ marginBottom: '0px' }}>Requested Claims</h3>}
                {request.details.claims.map((item, index) => (
                    <>
                        <Chip label={`${item.name}`} sx={{
                            margin: '2px 4px'
                        }} />
                    </>
                ))}
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>

                    {data.response.claimDetailsArray.map((item, index) => (
                        <Card style={{
                            flexBasis: '300px',
                            padding: '1rem',
                            margin: '2px'
                        }} variant="outlined">
                            <p style={{ fontWeight: 700, margin: '0px' }}>{item.name}</p>
                            {item.result ? <p style={{ margin: '0px', color: '#1f4f46', fontWeight: 700 }}>This meets the requirements to make this claim.</p> : <p style={{ margin: '0px', color: '#8b0000', fontWeight: 700 }}>This DOES NOT meets the requirements to make this claim.</p>}

                            {item.type && <p style={{}}>Type: {camelCaseToTitleCase(item.type)}</p>}

                            {item.condition && <p style={{}}>Condition: {item.condition}</p>}
                            {item && Array.isArray(item.claimLanguage) && item.claimLanguage.map((el, index) => (
                                <p>{el}</p>
                            ))}

                            {item && Array.isArray(item.notes) && item.notes.map((el, index) => (
                                <p>{el}</p>
                            ))}

                        </Card>
                    ))}
                </div>
            </div>

            <div>
                {request.details.ingredientCategories.length > 0 && <h3 style={{ marginBottom: '0px' }}>Ingredient Categories</h3>}
                {request.details.ingredientCategories.length > 0 && <span>Requested Ingredient Categories</span>}

                {request.details.ingredientCategories.map((item, index) => (
                    <>
                        <Chip label={`${item.name}`} sx={{
                            margin: '2px 4px'
                        }} />
                    </>
                ))}
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>

                    {data.response.ingredientCategoriesDetails.map((item, index) => (

                        item.exists ?
                            <Card style={{
                                flexBasis: '300px',
                                padding: '1rem',
                                margin: '2px'
                            }} variant="outlined">
                                <p style={{ fontWeight: 700 }}>{item.exists ? item.exists : "Unknown"} {item.name}</p>
                                <p style={{ margin: '0px' }}>Quantity: {item.quantity}{item.unit.abbreviation}</p>
                                <p style={{ margin: '0px' }}>High: {item.high} / Low: {item.low}</p>
                                <p style={{ margin: '0px' }}>RDI: {item.rdi} / DV: {((item.quantity / item.rdi) * 100).toFixed(2)}%</p>
                                <p style={{ margin: '0px' }}>Relative RDI: {(item.rdi * item.quantity)}</p>




                                {/* <p style={{ margin: '0px' }}>RDI: {item.rdi} / DV: {((item.quantity / item.rdi) * 100).toFixed(2)}%</p> */}



                            </Card>
                            :
                            null


                    ))}

                </div>
            </div>

            <div>
                {request.details.processingCategories.length > 0 && <h3 style={{ marginBottom: '0px' }}>Processing Categories</h3>}
                {request.details.processingCategories.length > 0 && <span>Requested Processing Categories</span>}

                {request.details.processingCategories.map((item, index) => (
                    <>
                        <Chip label={`${item.name}`} sx={{
                            margin: '2px 4px'
                        }} />
                    </>
                ))}
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>

                    {data.response.processingCategoriesDetails.map((item, index) => (
                        <Card style={{
                            flexBasis: '300px',
                            padding: '1rem',
                            margin: '2px'
                        }} variant="outlined">
                            <p style={{ fontWeight: 700 }}>{item.exists ? item.exists : "DOES NOT CONTAIN"} {item.name}</p>
                            {item.exists ?
                                <>
                                    <p style={{ margin: '0px' }}>Quantity: {item.quantity}{item.unit.abbreviation}</p>
                                    <p style={{ margin: '0px' }}>High: {item.high} / Low: {item.low}</p>
                                    <p style={{ margin: '0px' }}>RDI: {item.rdi} / DV: {((item.quantity / item.rdi) * 100).toFixed(2)}%</p>
                                    <p style={{ margin: '0px' }}>Relative RDI: {(item.rdi * item.quantity)}</p>

                                    {/* <p style={{ margin: '0px' }}>RDI: {item.rdi} / DV: {((item.quantity / item.rdi) * 100).toFixed(2)}%</p> */}



                                </>
                                :
                                <>
                                    <p style={{ margin: '0px' }}>Quantity: 0.00g</p>
                                    {/* <p style={{ margin: '0px' }}>High: {item.high} / Low: {item.low}</p> */}
                                    <p style={{ margin: '0px' }}>RDI: None</p>
                                </>
                            }
                        </Card>
                    ))}
                </div>
            </div>

            {data.nutritionLabel &&

                <div style={{
                    padding: '1rem'
                }}>
                    <div dangerouslySetInnerHTML={{ __html: data.nutritionLabel }} />
                    {/* <div style={{
                        // borderTop: '1px #767676 solid',
                        padding: '1rem 0rem',
                        margin: '1rem',
                    }}>
                        <Button style={{ marginRight: '8px' }} onClick={() => { console.log(window); window.print() }} variant="outlined">Print</Button>
                        <Button onClick={() => { console.log(window); window.print() }} variant="outlined">Export</Button>
                    </div> */}
                </div >
            }

            {data.getIngredientDeclaration && <h3>Ingredient Declaration</h3>}
            {data.getIngredientDeclaration && data.response.ingredientDeclation &&
                <div>
                    <p>Ingredient Declaration: {data.response.ingredientDeclation}</p>
                </div>
            }

            {data.includeAllergenDisclosure && <h3>Allergen Disclosure</h3>}
            {data.includeAllergenDisclosure && data.response.allergenDisclosure.allergensFound.length === 0 && data.response.allergenDisclosure.mayContains.length === 0 && <p>There are no allergens to disclose</p>}
            {data.includeAllergenDisclosure && data.response.allergenDisclosure.allergensFound.length > 0 &&
                <div>
                    <p>Contains: {data.response.allergenDisclosure.allergensFound.join(", ")} </p>
                </div>
            }
            {data.includeAllergenDisclosure && data.response.allergenDisclosure.mayContains.length > 0 &&
                <div>
                    <p>May contain: {data.response.allergenDisclosure.mayContains.join(", ")} </p>
                </div>
            }

            <div style={{ margin: "1rem", display: 'flex', alignItems: 'center' }}>
                <FormControl style={{ marginRight: "8px", flexBasis: '145px' }}>
                    <InputLabel id="select-type-label">Select Type</InputLabel>
                    <Select
                        size="small"
                        labelId="select-type-label"
                        value={selectedOption}
                        onChange={handleOptionChange}
                    >
                        <MenuItem value="csv">Download CSV</MenuItem>
                        <MenuItem value="json">Download JSON</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    disabled={!selectedOption}
                    onClick={handleDownload}
                    variant="contained"
                    color="primary"
                >
                    Download
                </Button>
            </div>

        </div>
    )
}

export default FoodCategoriesDisplay