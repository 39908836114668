import { setNotification } from '../store/NotificationContext'
import { startLoading, stopLoading } from '../store/LoadingSlice'
import api from '../utility/api'

const getFoodCategories = (body) => async (dispatch) => {
    dispatch(startLoading())

    return new Promise(async resolve => {

        const response = await api.post(`/api/v2-b2b/v2/food-categories`, body).catch(err => err.response)

        if (response.status === 400) {
            console.log("response status is 400")
            return dispatch(setNotification({
                msg: response.data.error
            }))
        }

        console.log("response food categories :: ", response)


        dispatch(stopLoading())
        return resolve(response.data)

    })

}

export default getFoodCategories