import {setNotification} from '../store/NotificationContext'
import api from '../utility/api'

const UseGetItemDetails = ({upc, productId, ingredientId}) => {
    console.log("UseGetItemDetails -- ", {upc, productId, ingredientId})

    let query = ``

    if (upc) query = `upc=${upc}`
    if (productId) query = `productId=${productId}`
    if (ingredientId) query = `ingredientId=${ingredientId}`

    return async (dispatch) => {
        return new Promise(async resolve => {
            console.log("UseGetItemDetails query -- ", {query})

            const response = await api.get(`/api/v2-b2b/v2/search/item?${query}`).catch(err => err.response)
            console.log("response get item details :: ", response)

            if (response.status === 400) {
                console.log("response status is 400", response.data)
                return dispatch(setNotification({
                    msg: response.data.error
                }))
            }

            console.log("response get item details :: ", response)

            return resolve(response.data)

        })
    }
}

export default UseGetItemDetails;

