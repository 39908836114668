import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NutritionLabel from "../widgets/nutritionLabel";
import ItemDetail from "../widgets/itemDetail";
import FoodCategories from "../widgets/foodCategories";
import SubproductCreate from "../widgets/subproductCreate";
import ProductCreate from "../widgets/productCreate";
import AutoComplete from "../widgets/autoComplete";
import SuccessConfirmation from "../widgets/elements/successConfirmation";
import CustomDiet from "../widgets/customDiet";
import FoodNameNormalization from "../widgets/foodNameNormalization";
import Authorization from "../components/authorization";
import ReferenceFoods from "../components/referenceFoods";
import CreateRecipe from "../components/createRecipe";
import RecipeInsights from "../components/recipeInsights";


const Navigation = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Authorization />} />

                {/* WIDGETS */}
                <Route path="/item-detail" element={<ItemDetail />} />
                <Route path="/food-categories" element={<FoodCategories />} />
                <Route path="/recipe-create" element={<SubproductCreate />} />
                <Route path="/product-create" element={<ProductCreate />} />
                <Route path="/nutrition-label" element={<NutritionLabel />} />
                <Route path="/autocomplete" element={<AutoComplete />} />
                <Route path="/custom-diet" element={<CustomDiet />} />
                <Route path="/food-name-normalization" element={<FoodNameNormalization />} />

                {/* GENERAL PAGES         */}
                <Route path="/reference-foods" element={<ReferenceFoods />} />
                <Route path="/create-recipe" element={<CreateRecipe />} />
                <Route path="/recipe-insight" element={<RecipeInsights />} />

                {/* OTHER */}
                <Route path="/success-confirmation" element={<SuccessConfirmation />} />

            </Routes>
        </Router>
    )
}

export default Navigation