import { TextField, List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import UseAutoComplete from '../actions/autoComplete';
import ProductDetails from './elements/productDetails';
import { useDispatch } from 'react-redux';

const AutoComplete = () => {
    const dispatch = useDispatch()
    const [query, setQuery] = useState("")
    const [results, setResults] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)

    const fetchAutoCompleteData = async () => {
        const data = await dispatch(UseAutoComplete(query))
        setResults(data)
    }

    // Get auto complete data
    useEffect(() => {

        if (query === "") {
            return setResults([])
        }

        if (query.length > 1) fetchAutoCompleteData()

    }, [fetchAutoCompleteData, query])

    console.log("Autocomplete Data", results)
    return (
        <div style={{ padding: '1rem' }}>
            <h1>Auto Complete</h1>

            <div style={{
                display: 'flex',
            }}>
                <TextField sx={{ marginRight: '8px' }} size="small" fullWidth onChange={e => setQuery(e.target.value)} value={query ? query : selectedItem ? `${selectedItem.id} - ${selectedItem.food_name}` : ""} />
                {/* <Button variant="outlined" onClick={() => setShowSelectedItem(!showSelectedItem)}>Search</Button> */}
            </div>

            <List style={{
                position: 'absolute',
                backgroundColor: '#fff',
                zIndex: 20,
            }}>

                {results.common?.length > 0 && <p>Common</p>}
                {results.common && results.common.map((item, index) => (
                    <ListItem disablePadding key={index}>
                        <ListItemButton component="a" onClick={() => { setSelectedItem(item); setQuery(""); setResults([]) }}>
                            <ListItemAvatar>
                                <Avatar>
                                    <img src={item.photo.highres} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.food_name} />
                        </ListItemButton>
                    </ListItem>
                ))}

                {results.branded?.length > 0 && <p>Branded</p>}
                {results.branded && results.branded.map((item, index) => (
                    <ListItem disablePadding key={index}>
                        <ListItemButton component="a" onClick={() => { setSelectedItem(item); setQuery(""); setResults([]) }}>
                            <ListItemAvatar>
                                <Avatar>
                                    <img src={item.photo.highres} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.food_name} />
                        </ListItemButton>
                    </ListItem>
                ))}

            </List>


            {selectedItem && <ProductDetails selectedItem={selectedItem} />}

        </div>
    )

}

export default AutoComplete