import { Autocomplete, Avatar, Button, Checkbox, Chip, CircularProgress, FormControl, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import UseAutoComplete from "../actions/autoComplete"
import { MdOutlineAddCircleOutline, MdOutlineCancel, MdOutlineRemoveCircleOutline } from "react-icons/md"
import UseGetUtility from "../actions/getUtility"
import getFoodCategories from "../actions/foodCategories"
import FoodCategoriesDisplay from "./elements/foodCategoriesDisplay"
import UseGetNutritionalLabel from "../actions/getNutritionLabel"

// dev testing
import { useDispatch, useSelector } from "react-redux"

const FoodCategories = () => {
    const dispatch = useDispatch()
    const { isLoading } = useSelector(state => state.loading)


    const [query, setQuery] = useState("")
    const [selectedIngredient, setSelectedIngredient] = useState({
        unit: {
            "_object": "Unit",
            "id": 44,
            "name": "Gram",
            "abbreviation": "g"
        }
    })
    const [results, setResults] = useState([])
    const [ingredients, setIngredients] = useState([])
    const [utility, setUtility] = useState(null)
    const [foodType, setFoodType] = useState("")
    const [servingSizeQuantity, setServingSizeQuantity] = useState("")
    const [servings, setServings] = useState("")
    const [data, setData] = useState(null)
    const [showFoodCategoriesDisplay, setShowFoodCategoriesDisplay] = useState(false)
    const [details, setDetails] = useState({
        diet: [],
        nutrients: [],
        claims: [],
        ingredientCategories: [],
        processingCategories: [],
    })
    const [getNFP, setGetNFP] = useState(false)
    const [getIngredientDeclaration, setGetIngredientDeclaration] = useState(false)
    const [includeOverallMatchScore, setIncludeOverallMatchScore] = useState(false)
    const [includeAllergenDisclosure, setIncludeAllergenDisclosure] = useState(false)
    const [loading, setLoading] = useState(false)

    const fetchUtilities = async () => {
        const { utility } = await dispatch(UseGetUtility("all"))

        setUtility(utility)
    }

    useEffect(() => {
        fetchUtilities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchAutoCompleteData = async () => {
        const data = await dispatch(UseAutoComplete(query))
        setResults(data)
    }

    // Get auto complete data
    useEffect(() => {

        if (query === "") {
            return setResults([])
        }

        if (query.length > 1) fetchAutoCompleteData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    const handleRemoveIngredient = (index) => {
        let newIngs = ingredients.filter((ing, i) => i !== index)
        setIngredients(newIngs)

    }

    const handleAddIngredient = () => {
        console.log(selectedIngredient)
        let ing = {
            id: selectedIngredient.id,
            masterId: selectedIngredient.masterId,
            name: selectedIngredient.food_name,
            quantity: selectedIngredient.quantity,
            unit: selectedIngredient.unit,
            type: selectedIngredient.common_type,
        }

        setIngredients([...ingredients, ing])
        setSelectedIngredient({
            unit: {
                "_object": "Unit",
                "id": 44,
                "name": "Gram",
                "abbreviation": "g"
            }
        })
        setQuery("")

    }

    const handleSubmit = async ({ test }) => {
        setLoading(true)
        if (test) {
            console.log("TEST- food categories:", {})
            let response = await dispatch(getFoodCategories({}))


            if (response) {
                let newData = { response }
                setData(newData)
                setShowFoodCategoriesDisplay(true)
            }
            return
        }

        let newData = data
        const body = {
            ingredients,
            details,
            type: foodType,
            servingSizeQuantity,
            servings,
            includeOverallMatchScore,
            includeAllergenDisclosure,
            getIngredientDeclaration
        }

        console.log("Preparing submit: ", body)

        if (getNFP) {
            let nfpIngredients = []
            for (let i = 0; i < ingredients.length; i++) {
                let newIngredient = {
                    id: ingredients[i].id,
                    masterId: ingredients[i].masterId,
                    name: ingredients[i].name,
                    quantity: ingredients[i].quantity,
                    unit: ingredients[i].unit.name,
                    unitId: ingredients[i].unit.id,
                    // unitId: ingredient.unit.abbreviation,
                    type: ingredients[i].type,
                }
                nfpIngredients.push(newIngredient)
            }

            let { nutritionLabel } = await dispatch(UseGetNutritionalLabel(nfpIngredients))
            newData = { ...newData, nutritionLabel }
            // setData({ ...data, nutritionLabel })
        }

        let response = await dispatch(getFoodCategories(body))

        // console.log("CLIENT - food categories:", response)

        if (response) {
            newData = { ...newData, response, includeOverallMatchScore, includeAllergenDisclosure, getIngredientDeclaration }
            setData(newData)
            setLoading(false)
            setShowFoodCategoriesDisplay(true)
        }
    }

    const handleRemoveDetail = (index, detailArray) => {
        let newDetails = details[detailArray].filter((item, i) => i !== index)

        setDetails({ ...details, [detailArray]: newDetails })

    }


    const validation = () => {
        let submitDisabled = false

        if (ingredients.length < 1) submitDisabled = true
        if (!servings) submitDisabled = true

        return { submitDisabled }
    }

    if (!utility || isLoading || loading) {
        return (
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress />
            </div>
        )
    }

    if (showFoodCategoriesDisplay) {
        // console.log("Food Categories Display Data :: ", data)
        return <FoodCategoriesDisplay data={data} request={{
            ingredients,
            details,
            type: foodType,
            servingSizeQuantity,
            servings,
        }} />
    }

    return (
        <div style={{
            // border: '1px red solid',
            padding: '0rem 1rem',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <div>

                <h1>Food Categories</h1>

                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>
                    <TextField sx={{ marginRight: '16px', marginBottom: '12px', flexBasis: '225px' }} label={"Servings"} onChange={e => setServings(e.target.value)} />
                    <TextField sx={{ marginRight: '16px', marginBottom: '12px', flexBasis: '225px' }} label={"Serving Size Quantity (in grams)"} onChange={e => setServingSizeQuantity(parseFloat(e.target.value))} />

                    <FormControl style={{
                        flexBasis: '225px',
                        marginRight: '16px',
                        marginBottom: '12px'
                    }}>
                        <InputLabel id="food-type-label-id">Food Type</InputLabel>
                        <Select labelId="food-type-label-id" label={"Food Type"} onChange={e => setFoodType(e.target.value)}>
                            <MenuItem>Select Food Type</MenuItem>
                            <MenuItem value={"indFood"}>Individual Food</MenuItem>
                            <MenuItem value={"meal"}>Meal</MenuItem>
                            <MenuItem value={"mainDish"}>Main dish</MenuItem>
                            <MenuItem value={"supp"}>Supplement</MenuItem>
                            <MenuItem value={"other"}>Other</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>
                    <TextField sx={{ marginRight: '16px', marginBottom: '12px', flexBasis: '225px' }} label={"Ingredient"} onChange={e => { setQuery(e.target.value) }} value={query} />
                    <TextField sx={{ marginRight: '16px', marginBottom: '12px', flexBasis: '225px' }} label={"Quantity"} onChange={e => setSelectedIngredient({ ...selectedIngredient, quantity: parseFloat(e.target.value) })} value={selectedIngredient?.quantity ? selectedIngredient.quantity : ""} />

                    <Autocomplete
                        value={selectedIngredient?.unit || null}
                        onChange={(e, v) => v && setSelectedIngredient({ ...selectedIngredient, unit: v })}
                        getOptionLabel={(item) => item?.name}
                        loading={utility.Unit?.length > 0}
                        loadingText="Loading"
                        disablePortal
                        id="units"
                        options={utility.Unit}
                        sx={{ marginRight: '16px', flexBasis: '225px' }}
                        renderInput={(params) => <TextField size="small" {...params} label="Units" />}
                    />


                    <Button disabled={!selectedIngredient?.id || !selectedIngredient?.quantity || !selectedIngredient?.unit} sx={{ marginRight: '16px', marginBottom: '12px' }} variant={!selectedIngredient.id && !selectedIngredient.quantity && !selectedIngredient.unit ? "contained" : "outlined"} onClick={handleAddIngredient}>Add</Button>
                </div>

                <List style={{
                    position: 'absolute',
                    backgroundColor: '#fff',
                    zIndex: 20,
                    top: 280
                }}>

                    {results.common?.length > 0 && <p>Common</p>}
                    {results.common && results.common.map((item, index) => (
                        <ListItem disablePadding key={index}>
                            <ListItemButton component="a" onClick={() => { setSelectedIngredient({ ...selectedIngredient, ...item }); setQuery(`${item.id} - ${item.food_name}`); setResults([]) }}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src={item.photo.highres} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.food_name} />
                            </ListItemButton>
                        </ListItem>
                    ))}

                    {results.branded?.length > 0 && <p>Branded</p>}
                    {results.branded && results.branded.map((item, index) => (
                        <ListItem disablePadding key={index}>
                            <ListItemButton component="a" onClick={() => { setSelectedIngredient({ ...selectedIngredient, ...item }); setQuery(`${item.id} - ${item.food_name}`); setResults([]) }}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src={item.photo.highres} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.food_name} />
                            </ListItemButton>
                        </ListItem>
                    ))}

                    {results.user?.length > 0 && <p>User Items</p>}
                    {results.user && results.user.map((item, index) => (
                        <ListItem disablePadding key={index}>
                            <ListItemButton component="a" onClick={() => { setSelectedIngredient({ ...selectedIngredient, ...item }); setQuery(`${item.id} - ${item.food_name}`); setResults([]) }}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src={item.photo.highres} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.food_name} />
                            </ListItemButton>
                        </ListItem>
                    ))}

                </List>

                <div style={{
                    padding: '8px 0px',
                    // borderBottom: '2px #767676 solid'
                }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 800 }}>Ingredient</TableCell>
                                    <TableCell style={{ fontWeight: 800 }} width={'20%'}>Quantity</TableCell>
                                    <TableCell style={{ fontWeight: 800 }} width={'20%'}>Unit</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {ingredients.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.quantity}</TableCell>
                                        <TableCell>{item.unit?.name}</TableCell>
                                        <TableCell><MdOutlineRemoveCircleOutline onClick={() => handleRemoveIngredient(index)} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <Checkbox label="Include Overall Match Score" onChange={e => setIncludeOverallMatchScore(e.target.checked)} value={includeOverallMatchScore} />
                        <p>Include Overall Match Score</p>
                    </div>

                    <div style={{
                        display: 'flex'
                    }}>
                        <Checkbox label="Nutrition Fact Panel" onChange={e => setGetNFP(e.target.checked)} value={getNFP} />
                        <p>Nutrition Fact Panel</p>
                    </div>

                    <div style={{
                        display: 'flex'
                    }}>
                        <Checkbox label="Nutrition Fact Panel" onChange={e => setIncludeAllergenDisclosure(e.target.checked)} value={includeAllergenDisclosure} />
                        <p>Allergen Disclosure</p>
                    </div>

                    <div style={{
                        display: 'flex'
                    }}>
                        <Checkbox label="Ingredient Declaration" onChange={e => setGetIngredientDeclaration(e.target.checked)} value={getIngredientDeclaration} />
                        <p>Ingredient Declaration</p>
                    </div>


                </div>

                <div>
                    {details.diet.map((item, index) => (
                        <>
                            <Chip key={index} label={`${item.name}`} sx={{ margin: '2px 4px' }} icon={<MdOutlineCancel style={{ zIndex: 20 }} onClick={() => handleRemoveDetail(index, "diet")} />} />
                        </>
                    ))}

                    {details.nutrients.map((item, index) => (
                        <>
                            <Chip key={index} label={`${item.name}`} sx={{ margin: '2px 4px' }} icon={<MdOutlineCancel style={{ zIndex: 20 }} onClick={() => handleRemoveDetail(index, "nutrients")} />} />
                        </>
                    ))}

                    {details.claims.map((item, index) => (
                        <>
                            <Chip key={index} label={`${item.name}`} sx={{ margin: '2px 4px' }} icon={<MdOutlineCancel style={{ zIndex: 20 }} onClick={() => handleRemoveDetail(index, "claims")} />} />
                        </>
                    ))}

                    {details.ingredientCategories.map((item, index) => (
                        <>
                            <Chip key={index} label={`${item.name}`} sx={{ margin: '2px 4px' }} icon={<MdOutlineCancel style={{ zIndex: 20 }} onClick={() => handleRemoveDetail(index, "ingredientCategories")} />} />
                        </>
                    ))}

                    {details.processingCategories.map((item, index) => (
                        <>
                            <Chip key={index} label={`${item.name}`} sx={{ margin: '2px 4px' }} icon={<MdOutlineCancel style={{ zIndex: 20 }} onClick={() => handleRemoveDetail(index, "processingCategories")} />} />
                        </>
                    ))}
                </div>

                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>

                    <div style={{
                        flexBasis: "400px",
                        marginRight: '8px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <p>Diets</p>
                            <MdOutlineAddCircleOutline onClick={() => setDetails({ ...details, diet: [...utility.Diet] })} />


                        </div>
                        <Autocomplete
                            onChange={(e, v) => v && setDetails({ ...details, diet: [...details.diet, v] })}
                            getOptionLabel={(item) => item?.name}
                            fullWidth
                            loading={utility.Diet?.length > 0}
                            loadingText="Loading"
                            disablePortal
                            id="diets"
                            options={utility.Diet}
                            sx={{ marginRight: '16px' }}
                            renderInput={(params) => <TextField size="small" {...params} label="Diets" />}
                        />
                    </div>

                    <div style={{
                        flexBasis: "400px",
                        marginRight: '8px'
                    }}>
                        <p>Nutrients</p>
                        <Autocomplete
                            onChange={(e, v) => v && setDetails({ ...details, nutrients: [...details.nutrients, v] })}
                            getOptionLabel={(item) => item.name}
                            fullWidth
                            loading={utility.Nutrient?.length > 0}
                            loadingText="Loading"
                            disablePortal
                            id="nutrients"
                            options={utility.Nutrient}
                            sx={{ marginRight: '16px' }}
                            renderInput={(params) => <TextField size="small" {...params} label="Nutrients" />}
                        />
                    </div>

                    <div style={{
                        flexBasis: "400px",
                        marginRight: '8px'
                    }}>
                        <p>Claims</p>
                        <Autocomplete
                            onChange={(e, v) => v && setDetails({ ...details, claims: [...details.claims, v] })}
                            getOptionLabel={(item) => item.name}
                            fullWidth
                            loading={utility.HealthClaim?.length > 0}
                            loadingText="Loading"
                            disablePortal
                            id="claims"
                            options={utility.HealthClaim}
                            sx={{ marginRight: '16px' }}
                            renderInput={(params) => <TextField size="small" {...params} label="Claims" />}
                        />
                    </div>

                    <div style={{
                        flexBasis: "400px",
                        marginRight: '8px'
                    }}>
                        <p>Ingredient Categories</p>
                        <Autocomplete
                            onChange={(e, v) => v && setDetails({ ...details, ingredientCategories: [...details.ingredientCategories, v] })}
                            getOptionLabel={(item) => item.name}
                            fullWidth
                            loading={utility.foodCategories?.length > 0}
                            loadingText="Loading"
                            disablePortal
                            id="ingredient-categories"
                            options={utility.foodCategories}
                            sx={{ marginRight: '16px' }}
                            renderInput={(params) => <TextField size="small" {...params} label="Ingredient Categories" />}
                        />
                    </div>

                    <div style={{
                        flexBasis: "400px",
                        marginRight: '8px'
                    }}>
                        <p>Processing Categories</p>
                        <Autocomplete
                            onChange={(e, v) => v && setDetails({ ...details, processingCategories: [...details.processingCategories, v] })}
                            getOptionLabel={(item) => item.name}
                            fullWidth
                            loading={utility.foodProcessing?.length > 0}
                            loadingText="Loading"
                            disablePortal
                            id="processing-categories"
                            options={utility.foodProcessing}
                            sx={{ marginRight: '16px' }}
                            renderInput={(params) => <TextField size="small" {...params} label="Processing Categories" />}
                        />
                    </div>

                </div>
            </div>

            <div style={{
                // padding: '1rem 0rem',
                margin: '1rem 0rem',
            }}>
                <Button style={{ marginRight: '16px' }} disabled={validation().submitDisabled} onClick={handleSubmit} variant="outlined">Submit</Button>
                <Button onClick={() => handleSubmit({ test: true })} variant="contained">DEV ONLY Submit</Button>
            </div>


        </div>
    )
}

export default FoodCategories