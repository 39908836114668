import { ThemeProvider, createTheme } from '@mui/material';
import './App.css';
import Notification from './components/notification';
import Navigation from './navigation';
import Navbar from './navigation/elements/navbar';
import Sidebar from './navigation/elements/sidebar';
import { store } from './store'
import { Provider } from 'react-redux'
import Widgets from './navigation/widgets';
import useWindowWidth from './utility/useWindowWidth';

const theme = createTheme({
  palette: {
    primary: {
      main: '#48817A',
      light: '#CCEAE7',
      dark: '#48817A'
    }
  }
})

const widget = (window.location.pathname.split('/')[1] === 'widget') ? true : false

function App() {
  const windowWidth = useWindowWidth();

  const mainAppStyle = {
    marginTop: '80px',
    // marginLeft: '220px',
    marginLeft: windowWidth >= 700 ? "220px" : "0px",
    height: '100%'
  }

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        {!widget &&
          <div className="App">
            <Notification />
            <Navbar />
            {windowWidth >= 700 && <Sidebar />}

            {/* <Sidebar /> */}
            <div style={mainAppStyle}>
              {/* <p>widget: {widget ? "true" : "false"}</p> */}
              <Navigation />
            </div>
          </div>
        }

        {widget &&
          <div className="App">

            <div>
              {/* <p>widget: {widget ? "true" : "false"}</p> */}
              <Widgets />
            </div>
          </div>
        }
      </Provider>
    </ThemeProvider>
  );
}

export default App;