import { TextField, Button } from "@mui/material"
import { useState } from "react"

const data = {
    apiKey: localStorage.getItem("apiKey"),
    clientId: localStorage.getItem("clientId"),
    authorization: localStorage.getItem("authorization")
}

const Authorization = () => {
    const [apiKey, setApiKey] = useState(null)
    const [clientId, setclientId] = useState(null)
    const [authorization, setAuthorization] = useState(null)

    const handleSetAuth = () => {
        if (apiKey) localStorage.setItem("apiKey", apiKey);
        if (clientId) localStorage.setItem("clientId", clientId);
        if (authorization) localStorage.setItem("authorization", authorization);

        window.location.reload()
    }

    return (
        <div style={{
            padding: '0rem 1rem'
        }}>
            <h1>Enter Auth Details</h1>
            <TextField style={{ marginBottom: '6px' }} fullWidth label="x-api-key" onChange={e => setApiKey(e.target.value)} value={apiKey} />
            <TextField style={{ marginBottom: '6px' }} fullWidth label="x-client-id" onChange={e => setclientId(e.target.value)} value={clientId} />
            <TextField style={{ marginBottom: '6px' }} fullWidth label="authorization" onChange={e => setAuthorization(e.target.value)} value={authorization} />
            <Button style={{ marginBottom: '6px' }} variant="outlined" onClick={handleSetAuth}>Set</Button>

            
            <p style={{ fontWeight: 700 }}>Auth keys are {!(data.apiKey && data.clientId && data.authorization) && "not"} set.</p>
            <p>{(data.apiKey || "").substring(0, 15)}</p>
            <p>{(data.clientId || "").substring(0, 15)}</p>
            <p>{(data.authorization || "").substring(0, 15)}</p>
        </div>

    )
}

export default Authorization