import { Avatar, Button, Chip, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { useState, useEffect } from "react"
import UseGetItemDetails from "../actions/getItemDetails"
import UseAutoComplete from '../actions/autoComplete';
import { setNotification } from "../store/NotificationContext"
import { useDispatch } from "react-redux";

const ItemDetail = () => {
    const widget = (window.location.pathname.split('/')[1] === 'widget') ? true : false
    const dispatch = useDispatch()
    const [upc, setUpc] = useState("")
    const [type, setType] = useState({})
    const [itemDetails, setItemDetails] = useState(null)
    const [results, setResults] = useState(null)
    const [query, setQuery] = useState("")
    const [selectedItem, setSelectedItem] = useState(null)

    const handleSubmitUPC = async () => {
        console.log("attempting to submit item detail:", { selectedItem })
        if (!selectedItem) return;

        let response = null
        // if (selectedItem.common_type === "product") response = await dispatch(UseGetItemDetails({ productId: selectedItem.id }))
        if (selectedItem?.common_type === "product") response = await dispatch(UseGetItemDetails({ productId: selectedItem?.id, upc: selectedItem?.id }))
        if (selectedItem?.common_type === "ingredient") response = await dispatch(UseGetItemDetails({ ingredientId: selectedItem?.id, upc: selectedItem?.id }))
        if (selectedItem?.common_type === "subproduct") response = await dispatch(UseGetItemDetails({ ingredientId: selectedItem?.id, upc: selectedItem?.id }))
        if (!selectedItem?.common_type) response = await dispatch(UseGetItemDetails({ ingredientId: selectedItem?.id, upc: selectedItem?.id }))
        // if (upcSelected) response = await dispatch(UseGetItemDetails({ upc: selectedItem.id }))
        setItemDetails(response)
        setResults([])

    }

    const fetchAutoCompleteData = async () => {
        const data = await dispatch(UseAutoComplete(query))
        setResults(data || [])
    }

    // Get auto complete data
    useEffect(() => {

        if (query === "") {
            return setResults([])
            // setSelectedItem(null)
        }

        if (query.length > 1) fetchAutoCompleteData()

    }, [query])

    console.log("RESULS === ", results)
    return (
        <div style={{
            padding: '1rem'
        }}>
            {!widget && <h1 style={{ marginBottom: "0px" }}>Item Detail</h1>}
            {/* <span>EXAMPLE UPC: 041789002113</span> */}

            <div style={{
                marginTop: "16px",
                display: "flex"

            }}>
                {/* <TextField size='small' style={{ marginRight: '8px' }} fullWidth label={"UPC"} onChange={e => setUpc(e.target.value)} /> */}
                <TextField sx={{ marginRight: '8px' }} size="small" fullWidth onChange={e => setQuery(e.target.value)} value={query} />
                <Button variant="outlined" onClick={handleSubmitUPC}>Search</Button>
            </div>


            <List style={{
                position: 'absolute',
                backgroundColor: '#fff',
                zIndex: 20,
            }}>

                {results?.common?.length > 0 && <p>Common</p>}
                {results?.common && results?.common.map((item, index) => (
                    <ListItem disablePadding>
                        <ListItemButton component="a" onClick={() => { setSelectedItem(item); setQuery(`${item.id} - ${item.food_name}`); setResults([]) }}>
                            <ListItemAvatar>
                                <Avatar>
                                    <img src={item.photo.highres} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.food_name} />
                        </ListItemButton>
                    </ListItem>
                ))}

                {results?.branded?.length > 0 && <p>Branded</p>}
                {results?.branded && results?.branded.map((item, index) => (
                    <ListItem disablePadding>
                        <ListItemButton component="a" onClick={() => { setSelectedItem(item); setQuery(item.food_name); setResults([]) }}>
                            <ListItemAvatar>
                                <Avatar>
                                    <img src={item.photo.highres} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.food_name} />
                        </ListItemButton>
                    </ListItem>
                ))}


                {results?.user?.length > 0 && <p>User Items</p>}
                {results?.user && results.user.map((item, index) => (
                    <ListItem disablePadding>
                        <ListItemButton component="a" onClick={() => { setSelectedItem(item); setQuery(item.food_name); setResults([]) }}>
                            <ListItemAvatar>
                                <Avatar>
                                    <img src={item.photo.highres} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.food_name} />
                        </ListItemButton>
                    </ListItem>
                ))}

            </List>

            {itemDetails &&
                <div>
                    <h3>{itemDetails.item.name}</h3>
                    <img src={itemDetails.item.imageUrl} />
                    <p>{itemDetails.item.description}</p>
                    <p>{itemDetails.item.ingredientDeclaration}</p>

                    {itemDetails.item.tags && <p>Tags</p>}
                    {itemDetails.item.tags && itemDetails.item.tags.map((item, index) => (
                        item.through.probabilityExists > .05 && <Chip style={{ marginRight: '8px', marginBottom: '4px' }} label={`${(item.through.probabilityExists === 1 ? "Contains" : item.through.probabilityExists === 0.5 ? "May contain" : null)} ${item.name}`} />
                    ))}


                    {itemDetails.item.certainContains && <p>certainContains</p>}
                    {itemDetails.item.certainContains &&
                        <TableContainer>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 800 }}>Ingredient</TableCell>
                                        {/* <TableCell style={{ fontWeight: 800 }}>Quantity</TableCell> */}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {itemDetails.item.certainContains.sort((a, b) => a.through.order - b.through.order).map((el, index) => (
                                        <TableRow>
                                            <TableCell>{el.name}</TableCell>
                                            {/* <TableCell>{el.through.quantity}g</TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>

                    }

                    {itemDetails.item.ingredients && <p>Ingredients</p>}
                    {itemDetails.item.ingredients &&
                        <TableContainer>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 800 }}>Ingredient</TableCell>
                                        <TableCell style={{ fontWeight: 800 }}>Quantity</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {itemDetails.item.ingredients.sort((a, b) => a.through.order - b.through.order).map((el, index) => (
                                        <TableRow>
                                            <TableCell>{el.name}</TableCell>
                                            <TableCell>{el.through.quantity}g</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>

                    }

                    {itemDetails.item.nutrients && <p>Nutrients</p>}
                    {itemDetails.item.nutrients &&
                        <TableContainer>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 800 }}>Nutrient</TableCell>
                                        <TableCell style={{ fontWeight: 800 }}>Quantity</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {itemDetails.item.nutrients.sort((a, b) => a.through.order - b.through.order).map((el, index) => (
                                        <TableRow>
                                            <TableCell>{el.name}</TableCell>
                                            <TableCell>{el.through.quantity}g</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>

                    }

                </div>
            }

        </div>
    )
}

export default ItemDetail