import { Button, Chip, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { MdCheckCircle } from "react-icons/md";
import UseGetIngredientIds from "../actions/getIngredientIds";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
}));


const FoodNameNormalization = () => {

    const dispatch = useDispatch()
    const classes = useStyles();

    const [queryNames, setQueryNames] = useState([])
    const [file, setFile] = useState(null)
    const [matchedIngredients, setMatchedIngredients] = useState([])
    const [name, setAddName] = useState("")

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUploadClick = () => {
        // Handle file upload logic here
        if (file) handleGetUploadData()

    };

    useEffect(() => {
        if (queryNames.length > 0) {
            fetchIngnredientIds()
        }

    }, [queryNames])

    const handleAddName = async () => {
        setQueryNames([...queryNames, name])
        setAddName("")
    }

    const fetchIngnredientIds = async () => {
        let { ingredients } = await dispatch(UseGetIngredientIds({ ingredients: queryNames }))

        if (ingredients && ingredients.length > 0) setMatchedIngredients(ingredients)

    }

    // EXPORTING FILE
    const downloadFile = ({ data, fileName, fileType }) => {
        // Create a blob with the data we want to download as a file
        const blob = new Blob([data], { type: fileType })
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToJson = e => {
        e.preventDefault()
        downloadFile({
            data: JSON.stringify(matchedIngredients),
            fileName: 'test.json',
            fileType: 'text/json',
        })
    }

    // IMPORTING FILE
    const fileReader = new FileReader();

    const handleGetUploadData = () => {
        fileReader.readAsText(file)

        fileReader.onload = function (event) {
            let csvOutput = event.target.result;
            let nameArray = csvOutput.split("\r\n")
            nameArray = nameArray.slice(1)
            setQueryNames([...queryNames, ...nameArray])

        }

        setFile(null)
    }

    const handleManualEntry = async (event, index) => {
        let temp = queryNames
        temp[index] = event.target.value
        await setQueryNames([...temp])
    }

    const handleDelete = (index) => {
        let temp = queryNames
        temp = temp.filter((el, i) => i !== index)
        setQueryNames(temp)

        let tempMatches = matchedIngredients
        tempMatches = tempMatches.filter((el, i) => i !== index)
        setMatchedIngredients(tempMatches)
    }

    return (
        <div style={{
            padding: '1rem',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <div>


                <h1>Food Name Normalization</h1>

                {/* HEADERLINE */}
                <div style={{
                    margin: '0rem 1rem',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>

                    <div style={{
                        flexBasis: '30%'
                    }}>
                        <p>Input</p>
                    </div>

                    <div style={{
                        flexBasis: '30%'
                    }}>
                        <p>Suggested</p>
                    </div>

                    <div style={{
                        flexBasis: '30%'
                    }}>
                        <p>Manual entry</p>
                    </div>

                    <div style={{
                        flexBasis: '10%',
                        marginLeft: '1rem',

                    }}>
                        <p>Done</p>
                    </div>

                </div>

                {/* ROW */}
                {queryNames.length > 0 && queryNames.map((item, index) => (

                    <div style={{
                        key: item + index,
                        margin: '0rem 1rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '4px'
                    }}>

                        <div style={{
                            flexBasis: '30%',
                        }}>
                            <span>{item}</span>
                        </div>

                        <div style={{
                            flexBasis: '30%',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            {matchedIngredients[index]?.match && <Chip style={{ marginRight: "2px" }} label={matchedIngredients[index]?.match?.name} />}
                        </div>

                        <div style={{
                            flexBasis: '30%',
                        }}>
                            <TextField size="small" label="Manual entry..." onChange={(e) => handleManualEntry(e, index)} />

                        </div>

                        <div style={{
                            flexBasis: '10%',
                            marginLeft: '1rem',
                        }}>
                            <div>
                                {matchedIngredients[index]?.match && <MdCheckCircle size={20} />}
                                {!matchedIngredients[index]?.match && <Chip style={{ marginRight: "2px" }} label={"Invalid"} onClick={() => handleDelete(index)} />}
                            </div>
                        </div>

                    </div>
                ))}



            </div>

            {/* FOOTER ACTIONS */}
            <div>
                {/* INGREDIENT INPUT */}
                <div style={{
                    margin: '2rem 1rem',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <TextField autoFocus size="small" label="Add additional food..." onChange={e => setAddName(e.target.value)} value={name} />
                    <div>
                        {name && <Button variant="contained" onClick={handleAddName}>Add</Button>}
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '1rem',
                }}>
                    {!file && <input
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={handleFileChange}
                    />}
                    {!file &&

                        <label htmlFor="contained-button-file">
                            <Button
                                variant="outlined"
                                color="primary"
                                component="span"
                            >
                                Upload File
                            </Button>
                        </label>
                    }
                    {file && (
                        <Button style={{ flexBasis: '25%', marginRight: '16px' }} variant="contained" color="primary" onClick={handleUploadClick}>
                            Submit File
                        </Button>
                    )}
                    <Button style={{ flexBasis: '25%', marginRight: '16px' }} variant="outlined" disabled>Save to Heali DB</Button>
                    <Button style={{ flexBasis: '25%', marginRight: '16px' }} variant="outlined" onClick={() => { setMatchedIngredients([]); setQueryNames([]) }}>Clear entries</Button>
                    <Button style={{ flexBasis: '25%' }} variant="outlined" onClick={e => exportToJson(e)}>Download annotations</Button>
                </div>

            </div>
        </div>
    )
}

export default FoodNameNormalization