import { Autocomplete, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { MdOutlineRemoveCircleOutline } from "react-icons/md"
import createProduct from "../actions/createProduct"
import NewProductConfirmation from "./elements/newProductConfirmation"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import UseGetUtility from "../actions/getUtility"

// TESTING
// import testProduct from '../data/newProductExample.json'

const ProductCreate = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [product, setProduct] = useState({
        servingSizeUnit: {
            "_object": "Unit",
            "id": 44,
            "name": "Gram",
            "abbreviation": "g"
        }
    })
    const [ingredients, setIngredients] = useState([])
    const [nutrients, setNutrients] = useState({})
    const [temp, setTemp] = useState({})
    const [showNewProductConfirmation, setShowNewProductConfirmation] = useState(false)
    const [utility, setUtility] = useState(null)


    const fetchUtilities = async () => {
        const { utility } = await dispatch(UseGetUtility("unit"))

        console.log(1, utility)
        if (utility) setUtility({ Unit: utility.Unit })
    }

    useEffect(() => {
        fetchUtilities()
    }, [])

    const handleRemoveIngredient = (index) => {
        let newIngs = ingredients.filter((ing, i) => i !== index)
        setIngredients(newIngs)
    }

    const addtoIngredients = () => {
        setIngredients([...ingredients, temp.ingredientName])
        setTemp({ ...temp, ingredientName: "" })
    }

    const handleReviewProduct = async () => {
        const newProduct = {
            ...product,
            ingredients,
            nutritionFacts: {
                nutrients
            }
        }

        console.log(newProduct)
        setTemp({ ...temp, newProduct })
        setShowNewProductConfirmation(true)
    }

    const handleSubmitProduct = async () => {
        const newProduct = {
            ...product,
            formulary: ingredients,
            nutritionFacts: {
                nutrients
            }
        }

        console.log(newProduct)

        const response = await dispatch(createProduct(newProduct, "product"))

        console.log(response)

        navigate('/success-confirmation', { state: { msg: "You have successfully created a product using Heali Intel", data: response.data, nextPath: '/product-create' } })


    }

    const validation = () => {
        let submitDisabled = false

        if (ingredients.length < 1) submitDisabled = true // Must have at least 1 ingredient
        if (Object.keys(nutrients).length < 12) submitDisabled = true // Must have all nutrients filled
        if (!product.name) submitDisabled = true // Must have a name
        if (!product.productTypes || !product.servingSizeQuantity || !product.servingSizeUnit) submitDisabled = true // Must have product type, serving size quantity, serving size unit

        return { submitDisabled }
        // return false
    }

    if (showNewProductConfirmation) {
        return <NewProductConfirmation newProduct={temp.newProduct} setShowNewProductConfirmation={setShowNewProductConfirmation} handleSubmitProduct={handleSubmitProduct} />
    }

    if (!utility) {
        return (
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div style={{
            // border: '1px red solid',
            padding: '0rem 1rem',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <div>


                <h1>Create a Product</h1>

                <TextField sx={{ marginTop: '16px' }} fullWidth label="Food Name" onChange={e => setProduct({ ...product, name: e.target.value })} value={product.name} />
                <TextField sx={{ marginTop: '16px' }} fullWidth label="Description" onChange={e => setProduct({ ...product, description: e.target.value })} value={product.description} />

                <div style={{ padding: '16px 0px', borderBottom: '2px #767676 solid', display: 'flex', flexWrap: 'wrap' }}>
                    <FormControl style={{
                        flexBasis: '300px',
                        marginRight: '16px'
                    }}>
                        <InputLabel id="food-type-label-id">Food Type</InputLabel>
                        <Select fullWidth labelId="food-type-label-id" label="Food Type" onChange={e => setProduct({ ...product, productTypes: e.target.value })} value={product.productTypes}>
                            <MenuItem>Select Food Type</MenuItem>
                            <MenuItem value={"indFood"}>Individual Food</MenuItem>
                            <MenuItem value={"meal"}>Meal</MenuItem>
                            <MenuItem value={"mainDish"}>Main dish</MenuItem>
                            <MenuItem value={"supp"}>Supplement</MenuItem>
                            <MenuItem value={"other"}>Other</MenuItem>
                        </Select>
                    </FormControl>

                    <div style={{
                        flexBasis: '300px',
                        marginRight: '16px'
                    }}>
                        {/* <p>Serving Size</p> */}
                        <TextField label="Serving Size" onChange={e => setProduct({ ...product, servingSizeQuantity: parseInt(e.target.value) })} value={product.servingSizeQuantity} />
                    </div>

                    <div style={{
                        flexBasis: '300px',
                        marginRight: '16px'
                    }}>
                        <Autocomplete
                            value={product?.servingSizeUnit || null}
                            onChange={(e, v) => v && setProduct({ ...product, servingSizeUnit: v })}
                            getOptionLabel={(item) => item?.name}
                            loading={utility?.Unit?.length > 0}
                            loadingText="Loading"
                            disablePortal
                            id="units"
                            options={utility.Unit}
                            // sx={{ marginRight: '16px', flexBasis: '225px' }}
                            renderInput={(params) => <TextField size="small" {...params} label="Units" />}
                        />
                    </div>

                </div>

                <div style={{
                    margin: '8px 0px',
                    display: 'flex'
                }}>
                    <TextField sx={{ marginRight: '16px' }} fullWidth label="Ingredient" onChange={e => setTemp({ ...temp, ingredientName: e.target.value })} value={temp.ingredientName} />
                    <Button variant="outlined" onClick={addtoIngredients}>Add</Button>
                </div>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 800 }}>Ingredient</TableCell>

                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {ingredients.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item}</TableCell>

                                    <TableCell><MdOutlineRemoveCircleOutline style={{ color: 'red' }} size={'18px'} onClick={() => handleRemoveIngredient(index)} /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div>
                    <h3>Nutrients</h3>

                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>

                            <p style={{ flexBasis: '120px' }}>Sugar</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, Sugar: { refQuantity: parseFloat(e.target.value) } })} value={nutrients.Sugar?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, Sugar: { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>
                            <p style={{ flexBasis: '120px' }}>Calories</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, Calories: { refQuantity: parseFloat(e.target.value) } })} value={nutrients.Calories?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, Calories: { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>
                            <p style={{ flexBasis: '120px' }}>Fat</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, Fat: { refQuantity: parseFloat(e.target.value) } })} value={nutrients.Fat?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, Fat: { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>
                            <p style={{ flexBasis: '120px' }}>Sodium</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, Sodium: { refQuantity: parseFloat(e.target.value) } })} value={nutrients.Sodium?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, Sodium: { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>
                            <p style={{ flexBasis: '120px' }}>Carbohydrate</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, Carbohydrate: { refQuantity: parseFloat(e.target.value) } })} value={nutrients.Carbohydrate?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, Carbohydrate: { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>
                            <p style={{ flexBasis: '120px' }}>Iron</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, Iron: { refQuantity: parseFloat(e.target.value) } })} value={nutrients.Iron?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, Iron: { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>
                            <p style={{ flexBasis: '120px' }}>Saturated Fat</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, "Saturated Fat": { refQuantity: parseFloat(e.target.value) } })} value={nutrients["Saturated Fat"]?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, "Saturated Fat": { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>
                            <p style={{ flexBasis: '120px' }}>Fiber</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, Fiber: { refQuantity: parseFloat(e.target.value) } })} value={nutrients.Fiber?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, Fiber: { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>
                            <p style={{ flexBasis: '120px' }}>Calcium</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, Calcium: { refQuantity: parseFloat(e.target.value) } })} value={nutrients.Calcium?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, Calcium: { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>
                            <p style={{ flexBasis: '120px' }}>Protein</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, Protein: { refQuantity: parseFloat(e.target.value) } })} value={nutrients.Protein?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, Protein: { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>
                            <p style={{ flexBasis: '120px' }}>Trans Fat</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, "Trans Fat": { refQuantity: parseFloat(e.target.value) } })} value={nutrients["Trans Fat"]?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, "Trans Fat": { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                        <div style={{
                            display: 'flex',
                            flexBasis: '500px',
                            margin: '8px 8px'
                        }}>
                            <p style={{ flexBasis: '120px' }}>Cholesterol</p>
                            <TextField sx={{ margin: '0px 8px' }} label="Quantity" onChange={e => setNutrients({ ...nutrients, Cholesterol: { refQuantity: parseFloat(e.target.value) } })} value={nutrients.Cholesterol?.refQuantity} />
                            <TextField disabled sx={{ margin: '0px 8px' }} label="Gram" onChange={e => setNutrients({ ...nutrients, Cholesterol: { refQuantity: parseFloat(e.target.value) } })} />
                        </div>

                    </div>

                </div>
            </div>

            <div>
                <Button disabled={validation().submitDisabled} onClick={handleSubmitProduct} variant="outlined">Review</Button>
            </div>

        </div>
    )
}

export default ProductCreate