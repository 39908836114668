import { Button } from "@mui/material"

const NewProductConfirmation = ({ newProduct, setShowNewProductConfirmation, handleSubmitProduct }) => {
    console.log("New Product :: ", newProduct)
    return (
        <div style={{
            padding: '.5rem',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <div style={{
                maxWidth: '800px'
            }}>

                <h1 style={{ textAlign: 'center' }}>Confirm New Product</h1>
                <h3 style={{ textAlign: 'center' }}>{newProduct.name} - {newProduct.description}</h3>
                <p style={{ textAlign: 'center' }}>Serving Size: {newProduct.servingSizeQuantity}{newProduct.servingSizeUnit.abbreviation} | Product Type: {newProduct.productTypes}</p>

                <h4>Ingredients:</h4>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>
                    {newProduct.ingredients.map((item, index) => (
                        <div style={{
                            padding: '.25rem',
                            margin: '.25rem',
                            border: '1px #ececec solid',
                            flexBasis: '180px',
                            borderRadius: '8px'
                        }}>
                            <span>{item}</span>
                        </div>
                    ))}
                </div>

                <h4>Nutrition Facts:</h4>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>
                    {Object.keys(newProduct.nutritionFacts.nutrients).map((item, index) => (
                        <div style={{
                            padding: '.25rem',
                            margin: '.25rem',
                            border: '1px #ececec solid',
                            flexBasis: '180px',
                            borderRadius: '8px'
                        }}>
                            <span>{item} - {Object.values(newProduct.nutritionFacts.nutrients)[index].refQuantity}g</span>
                        </div>
                    ))}
                </div>

            </div>

            <div style={{
                // padding: '1rem',
                maxWidth: '800px',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Button sx={{ margin: '1rem' }} variant='outlined' onClick={() => setShowNewProductConfirmation(false)}>Back</Button>
                <Button sx={{ margin: '1rem' }} variant='outlined' onClick={handleSubmitProduct}>Submit</Button>
            </div>
        </div>
    )
}

export default NewProductConfirmation